import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/core/services/api.service';

@Component({
  selector: 'app-verify-password',
  templateUrl: './verify-password.component.html',
  styleUrls: ['./verify-password.component.scss']
})
export class VerifyPasswordComponent implements OnInit {
  email: string;
  token: string;
  public password: FormControl = new FormControl('');
  public grp: FormGroup = new FormGroup({ password: this.password });
  public waiting: boolean | undefined;
  public loading$: boolean | undefined;
  turn = 0;

  constructor(
    public apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) private dlgData: any,
    private dialogRef: MatDialogRef<VerifyPasswordComponent>
  ) {
    this.email = this.dlgData.email;
    this.token = this.dlgData.token;
  }

  ngOnInit(): void {
  }

  verifyPassword(){
    if (this.password.value !== ""){
      console.log("password to 6ej nahi")
      this.apiService.verifyPassword(this.token, {
        email: this.email,
        password: this.password.value
      }).subscribe((resp)=>{
        console.log("Resp from verify password", resp);
        if (resp.status){
          this.dialogRef.close(true);
        } else{
          if (this.turn == 1){
            this.dialogRef.close(false);
          } else {
            this.turn = this.turn + 1;
            this.password.setErrors({
              try_again: true
            })
          }
        }
      }, (err)=>{
        console.log("error from verify password", err);
        if (this.turn == 1){
          this.dialogRef.close(false);
        } else {
          this.turn = this.turn + 1;
          this.password.setErrors({
            try_again: true
          })
        }
      })
    } else{
      this.password.setErrors({
        require: true
      })
      console.log(this.password.errors)
    }
  }

  closeDialog(status: boolean){
    this.dialogRef.close(status);
  }

}
