<div class="frow fsb mar-bottom-2">
  <h2 mat-dialog-title class="ellipsis">
      Products to Compare
  </h2>
  <button mat-button class="btn inverse" mat-raised-button color="primary" (click)="resetCompare()" [disabled]="allProducts.length == 0">Reset Compare</button>
</div>
<div class="products">
  <div class="product" *ngFor="let product of allProducts; index as ind">
    <div class="cancel cpointer" (click)="removeProduct(ind)">x</div>
    <div class="frow fsb">
      <div>
        <img src="{{backendIp}}product/{{product['_id']}}/front" alt="No Images available"/>
      </div>
      <div class="padding-1em ptc">
        <h3>{{product?.category?.category}}, {{product?.item?.item}}, and {{product?.item?.product_type}}</h3>
        <div class="link">{{product?.make}} </div>
        <div class="link">{{product?.model}} </div>
      </div>
    </div>
  </div>
</div>
<div class="basic-products" *ngIf="allProducts.length === 0">
  <h1>No Products to Compare</h1>
</div>

<!-- <form mat-dialog-content [formGroup]="productForm">
    <mat-form-field appearance="fill">
        <mat-label>Second Product</mat-label>
        <mat-select [(value)]="selectedSecondProduct" (valueChange)="selectSecondProduct($event)">
          <mat-option *ngFor="let product of secondProducts" [value]="product">
            {{product.make}} {{product.model}}
          </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Third Product</mat-label>
        <mat-select [(value)]="selectedThirdProduct" [disabled]="thirdDisabled">
          <mat-option *ngFor="let product of thirdProducts" [value]="product">
            {{product.make}} {{product.model}}
          </mat-option>
        </mat-select>
    </mat-form-field>
</form> -->
<mat-dialog-actions align="end">
    <button mat-button class="btn inverse" mat-dialog-close (click)="closeDialog()">Close</button>
    <button mat-button class="btn inverse" mat-raised-button color="primary" (click)="compareProduct()" [disabled]="allProducts.length <= 1">Compare</button>
</mat-dialog-actions>