<h2 mat-dialog-title class="ellipsis">
    Verify User
</h2>
<form mat-dialog-content [formGroup]="userForm">
    <mat-form-field appearance="outline" floatLabel="always" class="mfield-vk">
        <mat-label>Username</mat-label>
        <input matInput type="text" [formControl]="username" placeholder="Email Address">
        <mat-error *ngIf="username.invalid">
            <div *ngIf="username.errors?.required">required</div>
            <div *ngIf="username.errors?.pattern">Invalid Format</div>
        </mat-error>
        <mat-error *ngIf="username.invalid && username.errors?.backendException">
            invalid
        </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>User type</mat-label>
        <mat-select [formControl]="user_type" [required]="true">
          <mat-option value="seller">Seller</mat-option>
          <mat-option value="buyer">Buyer</mat-option>
        </mat-select>
        <mat-error *ngIf="user_type.invalid">
            <div *ngIf="user_type.errors?.required">required</div>
        </mat-error>
    </mat-form-field>
    <div class="ecv-loader frow-middle" *ngIf="apiRunning">
        <mat-progress-spinner mode="indeterminate" [diameter]='24' color='accent'></mat-progress-spinner>
        <span>
            <div [innerText]="'Please wait....'"></div>
        </span>
    </div>

    <div *ngIf="isOtp">

        <mat-form-field appearance="outline" floatLabel="always" class="mfield-vk">
            <mat-label>Enter 6 digit Auth Code*</mat-label>
            <input matInput type="number" [formControl]="otp" placeholder="Auth Code" required>
            <mat-error *ngIf="otp.invalid">
                <div *ngIf="otp.errors?.minlength">Length of OTP should be 6.</div>
            </mat-error>
            <mat-error *ngIf="otp.invalid && otp.errors?.backendException">
                invalid
            </mat-error>
        </mat-form-field>
    </div>
</form>
<mat-dialog-actions align="end">
    <button mat-button class="btn inverse" mat-raised-button color="primary" (click)="iscust ? verifyEmail(): verifyOtp()" [disabled]="userForm.invalid">{{iscust ? 'Verify Email': 'Verify Otp'}}</button>
</mat-dialog-actions>