<div class="centered-card-wrapper">
    <mat-card class="centered-card">
        <form>
            <mat-card-title class="mat-card-title">
                <h2>Forgot Password</h2>
            </mat-card-title>
            <div class="fcol m-fcol m-fw">
                <div class="fcol relative fx m-fw">
                    <mat-form-field appearance="outline" floatLabel="always" class="mfield-vk">
                        <mat-label>Username</mat-label>
                        <input matInput type="text" [formControl]="email" placeholder="Email Address" required>
                        <mat-error *ngIf="email.invalid">
                            <div *ngIf="email.errors?.required">required</div>
                            <div *ngIf="email.errors?.pattern">Invalid Format</div>
                        </mat-error>
                        <mat-error *ngIf="email.invalid && email.errors?.backendException">
                            invalid
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>User type</mat-label>
                        <mat-select [formControl]="user_type">
                          <mat-option value="seller">Seller</mat-option>
                          <mat-option value="buyer">Buyer</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <mat-dialog-actions align="end">
                    <button mat-button class="btn inverse" mat-raised-button color="primary" (click)="getPassword()" [disabled]="email.invalid">Get password</button>
                </mat-dialog-actions>
            </div>
        </form>
    </mat-card>
</div>