import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  blogs: any
  user: any
  userType: any
  isBlogLoading = true
  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.api.blogs().subscribe(data => {
      this.blogs = data
      this.isBlogLoading = false;
    }, (err) => {
      console.log(err)
    })
    this.user = localStorage.getItem('user');
    this.user = JSON.parse(this.user);
    this.userType = this.user.user_type
    console.log(localStorage.getItem("x-access-token"))
  }
  getImageSrc(documentId: string): string {
    return `http://iflowbackend.instrumentflow.com/blogimg/${documentId}`;
}

}
