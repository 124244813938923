export const BACKEND_IP = 'https://iflowbackend.instrumentflow.com/';
export const COMPARE_KEY = "ensqualInsFlw";
export const DAIMETER = 24;

export const FILTERS: any[] = [
    {
      'header': 'Product Type',
      'content': []
    },{
      'header': 'Product Item',
      'content': []
    },{
      'header': 'Accuracy',
      'content': []
    },{
      'header': 'Material',
      'content': []
    },{
      'header': 'Min Range',
      'content': []
    },{
      'header': 'Max Range',
      'content': []
    },{
      'header': 'Min Pressure',
      'content': []
    },{
      'header': 'Max Pressure',
      'content': []
    },{
      'header': 'Min Temprature',
      'content': []
    },{
      'header': 'Max Temprature',
      'content': []
    },{
      'header': 'Media Type',
      'content': []
    },{
      'header': 'Resolution',
      'content': []
    },{
      'header': 'Power Supply',
      'content': []
    },{
      'header': 'Output Signals',
      'content': ["MA", "RS232", "RS485"]
    },{
      'header': 'HART',
      'content': []
    },{
      'header': 'RS232',
      'content': []
    },{
      'header': 'RS485',
      'content': []
    },{
      'header': 'Element Material',
      'content': []
    },{
      'header': 'Housing',
      'content': []
    },{
      'header': 'Paint',
      'content': []
    },{
      'header': 'Range Limit',
      'content': []
    },{
      'header': 'IP',
      'content': []
    },{
      'header': 'Housing Material',
      'content': []
    },{
      'header': 'Hazardous Zone',
      'content': []
    },{
      'header': 'Dimension',
      'content': []
    }
];

export const APPLIEDFILTERS: any = {
    'Product Type': [],
    'Product Item': [],
    'Accuracy': [],
    'Material': [],
    'Min Range': [],
    'Max Range': [],
    'Min Pressure': [],
    'Max Pressure': [],
    'Min Temprature': [],
    'Max Temprature': [],
    'Media Type': [],
    'Resolution': [],
    'Power Supply': [],
    'Output Signals': [],
    'HART': [],
    'RS232': [],
    'RS485': [],
    'Element Material': [],
    'Housing': [],
    'Paint': [],
    'Range Limit': [],
    'IP': [],
    'Housing Material': [],
    'Hazardous Zone': [],
    'Dimension': [],
}