import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FILTERS, APPLIEDFILTERS } from '../../constants';

@Component({
  selector: 'app-mobile-filters',
  templateUrl: './mobile-filters.component.html',
  styleUrls: ['./mobile-filters.component.scss']
})
export class MobileFiltersComponent implements OnInit {
  // filters: any[] = [
  //   {
  //     'header': 'Product Item',
  //     'content': []
  //   },{
  //     'header': 'Product Type',
  //     'content': []
  //   },{
  //     'header': 'Accuracy',
  //     'content': []
  //   },{
  //     'header': 'Material',
  //     'content': []
  //   },{
  //     'header': 'Min Range',
  //     'content': []
  //   },{
  //     'header': 'Max Range',
  //     'content': []
  //   },{
  //     'header': 'Min Pressure',
  //     'content': []
  //   },{
  //     'header': 'Max Pressure',
  //     'content': []
  //   },{
  //     'header': 'Min Temprature',
  //     'content': []
  //   },{
  //     'header': 'Max Temprature',
  //     'content': []
  //   },{
  //     'header': 'Media Type',
  //     'content': []
  //   },{
  //     'header': 'Resolution',
  //     'content': []
  //   },{
  //     'header': 'Power Supply',
  //     'content': []
  //   },{
  //     'header': 'Output Signals',
  //     'content': ["MA", "RS232", "RS485"]
  //   },{
  //     'header': 'HART',
  //     'content': []
  //   },{
  //     'header': 'RS232',
  //     'content': []
  //   },{
  //     'header': 'RS485',
  //     'content': []
  //   },{
  //     'header': 'Element Material',
  //     'content': []
  //   },{
  //     'header': 'Housing',
  //     'content': []
  //   },{
  //     'header': 'Paint',
  //     'content': []
  //   },{
  //     'header': 'Range Limit',
  //     'content': []
  //   },{
  //     'header': 'IP',
  //     'content': []
  //   },{
  //     'header': 'Housing Material',
  //     'content': []
  //   },{
  //     'header': 'Hazardous Zone',
  //     'content': []
  //   },{
  //     'header': 'Dimension',
  //     'content': []
  //   }
  // ];
  filters = FILTERS;
  appliedFilters = APPLIEDFILTERS;
  // appliedFilters: any = {
  //   'Product Item': [],
  //   'Product Type': [],
  //   'Accuracy': [],
  //   'Material': [],
  //   'Min Range': [],
  //   'Max Range': [],
  //   'Min Pressure': [],
  //   'Max Pressure': [],
  //   'Min Temprature': [],
  //   'Max Temprature': [],
  //   'Media Type': [],
  //   'Resolution': [],
  //   'Power Supply': [],
  //   'Output Signals': [],
  //   'HART': [],
  //   'RS232': [],
  //   'RS485': [],
  //   'Element Material': [],
  //   'Housing': [],
  //   'Paint': [],
  //   'Range Limit': [],
  //   'IP': [],
  //   'Housing Material': [],
  //   'Hazardous Zone': [],
  //   'Dimension': [],
  // }
  searchedProducts: any = [];
  products: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public dlgData: any,
    private dialogRef: MatDialogRef<MobileFiltersComponent>,
  ) {
    this.products = this.dlgData.products;
    this.searchedProducts = this.dlgData.searchedProducts
    console.log("at in it products for mobile filters");
    console.log(this.products)
  }

  ngOnInit(): void {
  }

  addFilter(event: any, header: string){
    console.log("eventttt", event);
    if (event.checked){
      if(this.appliedFilters[header].indexOf(event.source.value) == -1){
        this.appliedFilters[header].push(event.source.value);
      }
    } else{
      let eleIndex = this.appliedFilters[header].indexOf(event.source.value);
      if(eleIndex !== -1){
        this.appliedFilters[header].splice(eleIndex, 1);
      }
    }
  }

  applyFilters(){
    let productsToFilter = this.searchedProducts;
    let filteredProducts: any[] = [];
    let anyFilter = false;
    // Object.keys(this.appliedFilters).forEach(element => {
    //   productsToFilter.forEach((product:any) => {
    //     if (element === 'Product Type'){
    //       if(product?.item.product_type && this.appliedFilters[element].indexOf(product?.item.product_type) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Product Item'){
    //       if(product?.item?.item && this.appliedFilters[element].indexOf(product?.item?.item) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //       productsToFilter = filteredProducts;
    //     } else if (element === 'Accuracy'){
    //       if(product?.basic_details?.accuracy && this.appliedFilters[element].indexOf(product?.basic_details?.accuracy) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Material'){
    //       if(product?.basic_details?.material && this.appliedFilters[element].indexOf(product?.basic_details?.material) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Min Range'){
    //       if(product?.basic_details?.min_range && this.appliedFilters[element].indexOf(product?.basic_details?.min_range) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Max Range'){
    //       if(product?.basic_details?.max_range && this.appliedFilters[element].indexOf(product?.basic_details?.max_range) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Min Pressure'){
    //       if(product?.basic_details?.min_pressure && this.appliedFilters[element].indexOf(product?.basic_details?.min_pressure) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Max Pressure'){
    //       if(product?.basic_details?.max_pressure && this.appliedFilters[element].indexOf(product?.basic_details?.max_pressure) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Min Temprature'){
    //       if(product?.basic_details?.min_temprature && this.appliedFilters[element].indexOf(product?.basic_details?.min_temprature) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Max Temprature'){
    //       if(product?.basic_details?.max_temprature && this.appliedFilters[element].indexOf(product?.basic_details?.max_temprature) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Media Type'){
    //       if(product?.basic_details?.media_type && this.appliedFilters[element].indexOf(product?.basic_details?.media_type) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Resolution'){
    //       if(product?.basic_details?.resolution && this.appliedFilters[element].indexOf(product?.basic_details?.resolution) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Power Supply'){
    //       if(product?.item?.item && this.appliedFilters[element].indexOf(product?.item?.item) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Output Signals'){
    //       if(this.appliedFilters[element].indexOf("MA") !== -1 && product?.output_signals?.ma ){
    //         if(product?.output_signals?.ma === true){
    //           if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //           anyFilter = true;
    //         }
    //       } else if(this.appliedFilters[element].indexOf("RS232") !== -1 && product?.output_signals?.rs232 ){
    //         if(product?.output_signals?.rs232 === true){
    //           if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //           anyFilter = true;
    //         }
    //       } else if(this.appliedFilters[element].indexOf("RS485") !== -1 && product?.output_signals?.rs485 ){
    //         if(product?.output_signals?.rs485 === true){
    //           if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //           anyFilter = true;
    //         }
    //       }
    //     } else if (element === 'HART'){
    //       if(product?.communication_software?.hart && this.appliedFilters[element].indexOf(product?.communication_software?.hart) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'RS232'){
    //       if(product?.communication_software?.rs232 && this.appliedFilters[element].indexOf(product?.communication_software?.rs232) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'RS485'){
    //       if(product?.communication_software?.rs485 && this.appliedFilters[element].indexOf(product?.communication_software?.rs485) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Element Material'){
    //       if(product?.transmitter?.element_material && this.appliedFilters[element].indexOf(product?.transmitter?.element_material) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Housing'){
    //       if(product?.transmitter?.housing && this.appliedFilters[element].indexOf(product?.transmitter?.housing) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Paint'){
    //       if(product?.transmitter?.paint && this.appliedFilters[element].indexOf(product?.transmitter?.paint) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Range Limit'){
    //       if(product?.transmitter?.range_limit && this.appliedFilters[element].indexOf(product?.transmitter?.range_limit) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'IP'){
    //       if(product?.enclosure?.ip_ratting && this.appliedFilters[element].indexOf(product?.enclosure?.ip_ratting) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Housing Material'){
    //       if(product?.enclosure?.housing_material && this.appliedFilters[element].indexOf(product?.enclosure?.housing_material) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Hazardous Zone'){
    //       if(product?.enclosure?.hazardous_zone_class && this.appliedFilters[element].indexOf(product?.enclosure?.hazardous_zone_class) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     } else if (element === 'Dimension'){
    //       if(product?.enclosure?.dimension && this.appliedFilters[element].indexOf(product?.enclosure?.dimension) !== -1){
    //         if (filteredProducts.indexOf(product) == -1){
    //           filteredProducts.push(product);
    //         }
    //         anyFilter = true;
    //       }
    //     }
    //   });
    //   console.log(`completed ${element} filter`);
    //   console.log(anyFilter);

    //   if (anyFilter){
    //     productsToFilter = filteredProducts;
    //   }
    //   console.log(productsToFilter);
    // });

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Product Type';
      if (element === 'Product Type'){
        if(product?.item.product_type && this.appliedFilters[element].indexOf(product?.item.product_type) !== -1){
          if (filteredProducts.indexOf(product) == -1){
            filteredProducts.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      } 
      if (tempFlag){
        productsToFilter = filteredProducts;
      } else{
        productsToFilter = this.searchedProducts;
      }
    });
    let temp: any = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Product Item';
      if (element === 'Product Item'){
        if(product?.item?.item && this.appliedFilters[element].indexOf(product?.item?.item) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      }
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Accuracy';
      if (element === 'Accuracy'){
        if(product?.basic_details?.accuracy && this.appliedFilters[element].indexOf(product?.basic_details?.accuracy) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      } 
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Material';
      if (element === 'Material'){
        if(product?.basic_details?.material && this.appliedFilters[element].indexOf(product?.basic_details?.material) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      } 
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Min Range';
      if (element === 'Min Range'){
        if(product?.basic_details?.min_range && this.appliedFilters[element].indexOf(product?.basic_details?.min_range) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      }
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Max Range';
      if (element === 'Max Range'){
        if(product?.basic_details?.max_range && this.appliedFilters[element].indexOf(product?.basic_details?.max_range) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      } 
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Min Pressure';
      if (element === 'Min Pressure'){
        if(product?.basic_details?.min_pressure && this.appliedFilters[element].indexOf(product?.basic_details?.min_pressure) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      }
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Max Pressure';
      if (element === 'Max Pressure'){
        if(product?.basic_details?.max_pressure && this.appliedFilters[element].indexOf(product?.basic_details?.max_pressure) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      }
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Min Temprature';
      if (element === 'Min Temprature'){
        if(product?.basic_details?.min_temprature && this.appliedFilters[element].indexOf(product?.basic_details?.min_temprature) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      } 
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Max Temprature';
      if (element === 'Max Temprature'){
        if(product?.basic_details?.max_temprature && this.appliedFilters[element].indexOf(product?.basic_details?.max_temprature) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      }
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Media Type';
      if (element === 'Media Type'){
        if(product?.basic_details?.media_type && this.appliedFilters[element].indexOf(product?.basic_details?.media_type) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      } 
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Resolution';
      if (element === 'Resolution'){
        if(product?.basic_details?.resolution && this.appliedFilters[element].indexOf(product?.basic_details?.resolution) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      }
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Power Supply';
      if (element === 'Power Supply'){
        if(product?.item?.item && this.appliedFilters[element].indexOf(product?.item?.item) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      }
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Output Signals';
      if (element === 'Output Signals'){
        if(this.appliedFilters[element].indexOf("MA") !== -1 && product?.output_signals?.ma ){
          if(product?.output_signals?.ma === true){
            if (temp.indexOf(product) == -1){
              temp.push(product);
            }
            tempFlag = true;
            anyFilter = true;
          }
        } else if(this.appliedFilters[element].indexOf("RS232") !== -1 && product?.output_signals?.rs232 ){
          if(product?.output_signals?.rs232 === true){
            if (temp.indexOf(product) == -1){
              temp.push(product);
            }
            tempFlag = true;
            anyFilter = true;
          }
        } else if(this.appliedFilters[element].indexOf("RS485") !== -1 && product?.output_signals?.rs485 ){
          if(product?.output_signals?.rs485 === true){
            if (temp.indexOf(product) == -1){
              temp.push(product);
            }
            tempFlag = true;
            anyFilter = true;
          }
        }
      }
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'HART';
      if (element === 'HART'){
        if(product?.communication_software?.hart && this.appliedFilters[element].indexOf(product?.communication_software?.hart) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      } 
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'RS232';
      if (element === 'RS232'){
        if(product?.communication_software?.rs232 && this.appliedFilters[element].indexOf(product?.communication_software?.rs232) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      }
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'RS485';
      if (element === 'RS485'){
        if(product?.communication_software?.rs485 && this.appliedFilters[element].indexOf(product?.communication_software?.rs485) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      }
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Element Material';
      if (element === 'Element Material'){
        if(product?.transmitter?.element_material && this.appliedFilters[element].indexOf(product?.transmitter?.element_material) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      } 
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Housing';
      if (element === 'Housing'){
        if(product?.transmitter?.housing && this.appliedFilters[element].indexOf(product?.transmitter?.housing) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      } 
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Paint';
      if (element === 'Paint'){
        if(product?.transmitter?.paint && this.appliedFilters[element].indexOf(product?.transmitter?.paint) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      }
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Range Limit';
      if (element === 'Range Limit'){
        if(product?.transmitter?.range_limit && this.appliedFilters[element].indexOf(product?.transmitter?.range_limit) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      } 
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'IP';
      if (element === 'IP'){
        if(product?.enclosure?.ip_ratting && this.appliedFilters[element].indexOf(product?.enclosure?.ip_ratting) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      } 
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Housing Material';
      if (element === 'Housing Material'){
        if(product?.enclosure?.housing_material && this.appliedFilters[element].indexOf(product?.enclosure?.housing_material) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      }
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Hazardous Zone';
      if (element === 'Hazardous Zone'){
        if(product?.enclosure?.hazardous_zone_class && this.appliedFilters[element].indexOf(product?.enclosure?.hazardous_zone_class) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      } 
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });

    temp = [];

    productsToFilter.forEach((product:any) => {
      let tempFlag = false;
      let element = 'Dimension';
      if (element === 'Dimension'){
        if(product?.enclosure?.dimension && this.appliedFilters[element].indexOf(product?.enclosure?.dimension) !== -1){
          if (temp.indexOf(product) == -1){
            temp.push(product);
          }
          tempFlag = true;
          anyFilter = true;
        }
      }
      if (tempFlag){
        productsToFilter = temp;
        filteredProducts = temp;
      }
    });
    if (anyFilter){
      this.products = filteredProducts;
      this.dialogRef.close({
        status: true,
        products: this.products
      });
    } else{
      this.products = this.searchedProducts;
      this.dialogRef.close({
        status: true,
        products: this.products
      });
    }
  }

  closeDialog(){
    this.dialogRef.close();
  }

  applyFilterActions(){
    this.applyFilters();
  }

}
