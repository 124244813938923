import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/core/services/api.service';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss'],
})
export class BlogDetailsComponent implements OnInit {
  blog: any;
  blogId: any;
  base64String: string = ''; // Initially empty
  imageSrc: string = ''; // Image source
  isBlogLoading = true
  constructor(
    private api: ApiService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.blogId = this.activatedRoute.snapshot.paramMap.get('id');
    console.log(this.blogId);
    this.api.findBlogById(this.blogId).subscribe(
      (blog) => {
        this.blog = blog;
        this.isBlogLoading = false
        console.log(this.blog);
        setTimeout(() => {
          // Simulated Base64 string stored in the database
          const bufferData = this.blog['0'].blogimg;
          console.log(this.blog['0'].blogimg);
          this.base64String = this.arrayBufferToBase64(bufferData.data);
          this.imageSrc = 'data:image/png;base64,' + this.base64String;
        }, 1000);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  arrayBufferToBase64(buffer: number[]) {
    let binary = '';
    for (let i = 0; i < buffer.length; i++) {
      binary += String.fromCharCode(buffer[i]);
    }
    return btoa(binary);
  }
}
