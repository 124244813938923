<div class="centered-card-wrapper">
    <mat-card class="centered-card">
        <form>
            <mat-card-title class="mat-card-title">
                <h1 class="homepage-app-name cpointer"><img src="../../../../assets/applogo/Brandlogo.svg" (click)="openHomepage()" alt=""></h1>
                <h2>Create Account for InstrumentFlow</h2>
            </mat-card-title>
            <div class="fcol m-fcol m-fw">
                <div class="fcol relative fx m-fw">
                    <mat-form-field appearance="outline" floatLabel="always" class="mfield-vk">
                        <mat-label>Username</mat-label>
                        <input matInput type="text" [formControl]="username" placeholder="Email Address">
                        <mat-error *ngIf="username.invalid">
                            <div *ngIf="username.errors?.required">required</div>
                            <div *ngIf="username.errors?.pattern">Invalid Format</div>
                        </mat-error>
                        <mat-error *ngIf="username.invalid && username.errors?.backendException">
                            invalid
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Password</mat-label>
                        <input matInput type="password" [formControl]="password" placeholder="Password">
                        <mat-error *ngIf="password.invalid">
                            <div *ngIf="password.errors?.required">required</div>
                            <div *ngIf="password.errors?.minlength">Length of password should be 8.</div>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>User type</mat-label>
                        <mat-select [formControl]="user_type">
                          <mat-option value="seller">Seller</mat-option>
                          <mat-option value="buyer">Buyer</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <button mat-raised-button class="btn sbmt fas-bottom" color="primary" (click)="signin()" [disabled]="grp.invalid">
                    <i class="ms-Icon ms-Icon--UnlockSolid" *ngIf="!waiting && !loading$" aria-hidden="true"></i>
                    <span *ngIf="!waiting && !loading$">Signin</span>
                    <span *ngIf="waiting || loading$">
                      <mat-progress-spinner mode="indeterminate" diameter="16" color="{{ waiting ? 'background' : 'primary' }}">
                      </mat-progress-spinner>
                    </span>
                    <span *ngIf="waiting">Trying Key...</span>
                    <span *ngIf="loading$">Loading</span>
                </button>
                <div class="frow fjc-end marg-top-5">
                    <span class="disable-op">Already have an account? </span>
                    <button class="mat-focus-indicator lnk-btn mat-button mat-button-base" (click)="login()">Login</button>
                </div>
            </div>
        </form>
    </mat-card>
</div>