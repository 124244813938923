import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/core/services/api.service';

@Component({
  selector: 'app-verify-user',
  templateUrl: './verify-user.component.html',
  styleUrls: ['./verify-user.component.scss']
})
export class VerifyUserComponent implements OnInit {
  userForm: FormGroup;
  iscust: boolean = true;
  isOtp = false;
  apiRunning: boolean = false;
  public otp: FormControl = new FormControl('', [Validators.minLength(6)]);
  public user_type: FormControl = new FormControl(null,[
    Validators.required]);
  public username: FormControl = new FormControl('',[
    Validators.required,
    Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$')
  ]);
  user: any;
  token: any;

  constructor(
    private _formBuilder: FormBuilder,
    private apiService: ApiService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private dlgData: any,
    private dialogRef: MatDialogRef<VerifyUserComponent>
  ) {
    this.userForm = this._formBuilder.group({
      username: this.username,
      user_type: this.user_type,
      otp: this.otp
    });
    
  }

  ngOnInit(): void {
    this.user = localStorage.getItem('user');
    this.user = JSON.parse(this.user);
    if (!this.user || !this.user.token){
      localStorage.removeItem('user');
      // this.router.navigateByUrl("/");
    } else{
      this.token = this.user.token;
    }
  }

  closeDialog(){
    this.dialogRef.close();
  }

  verifyEmail(){
    console.log(this.userForm.value);
    this.apiService.resendOtp(
      this.userForm.value.username,
      this.userForm.value.user_type
    ).subscribe((resp) =>{
      this.isOtp = true;
      this.iscust = !this.iscust;
      this.snackBar.open('OTP has been sent in your mail. Check your mail.', 'OK', {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
    }, (err) =>{
      this.snackBar.open(err.error.error, 'OK', {
        duration: 7500,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      this.closeDialog();
    })
  }

  verifyOtp(){
    this.apiService.verifyOtp(
      this.userForm.value.username,
      this.userForm.value.otp,
      this.userForm.value.user_type
    ).subscribe((resp) =>{
      this.snackBar.open('User has been verified try to login.', 'OK', {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      this.closeDialog();
    }, (err) =>{
      this.snackBar.open(err.error.error, 'OK', {
        duration: 7500,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      this.closeDialog();
    })
  }

}

