import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SessionExpiredComponent } from 'src/app/authentication/components/session-expired/session-expired.component';
import { VerifyPasswordComponent } from 'src/app/authentication/components/verify-password/verify-password.component';
import { ApiService } from './api.service';
import { deliveryDateEnum } from '../enum';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  isMobile = false;
  isTabletLandscape = false;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    public api: ApiService,
    private snackBar: MatSnackBar,
    private session: SessionService
  ) {}

  getDialogConfiguration(
    data: any,
    autoFocus: boolean = true
  ): Record<string, any> {
    let config: Record<string, any>;
    if (this.isMobile) {
      config = {
        width: '100vw',
        height: '100vh',
        maxWidth: '100vw',
        maxHeight: '100vh',
        data,
        panelClass: 'mobile-dlg',
        disableClose: true,
      };
    } else if (this.isTabletLandscape && !!data?.dialogConfigForInvite) {
      config = {
        width: '90vw',
        disableClose: true,
        autoFocus,
        data,
      };
    } else {
      config = {
        width: '600px',
        disableClose: true,
        autoFocus,
        data,
      };
    }
    return config;
  }

  tokenVerification(user: any) {
    if (!!user && !!user.token) {
    } else {
      this.redirectToSessionExpired();
    }
  }

  redirectToSessionExpired() {
    // this.session.session.subscribe((currentSession)=>{
    //   if(currentSession){
    //     this.session.session$.next(false);
    //     localStorage.removeItem('user');
    //     const config = this.getDialogConfiguration({});
    //     const dialogRef = this.dialog.open(SessionExpiredComponent, config);

    //     dialogRef.afterClosed().subscribe(page => {
    //       this.router.navigateByUrl("/login");
    //     });
    //   }
    // })
    this.session.session$.next(false);
    localStorage.removeItem('user');
    const config = this.getDialogConfiguration({});
    const dialogRef = this.dialog.open(SessionExpiredComponent, config);

    dialogRef.afterClosed().subscribe((page) => {
      this.router.navigateByUrl('/login');
    });
  }

  enableForm(formToBeEnabled: any, formFlag: boolean) {
    if (formToBeEnabled.disabled) {
      formToBeEnabled.enable();
      formFlag = !formFlag;
    }
  }

  updateUserInCache(token: any) {
    console.log(token);
    this.api.getCuurentUser(token).subscribe((user) => {
      localStorage.setItem('user', JSON.stringify(user));
    });
  }

  async verifyPassword(email: string, token: string): Promise<boolean | void> {
    return new Promise((resolve, reject) => {
      let config = this.getDialogConfiguration({});
      config.data.email = email;
      config.data.token = token;
      const dialogRef = this.dialog.open(VerifyPasswordComponent, config);
      dialogRef.afterClosed().subscribe((verifiedPassword) => {
        console.log('from utility', verifiedPassword);
        if (!verifiedPassword) {
          this.redirectToSessionExpired();
        }
        resolve(verifiedPassword);
      });
    });
  }

  calculateDeliveryDate(date: string) {
    var dateList = date.split(' ');
    let dateUnit = dateList[1];
    let dateNumber = dateList[0];
    let days = 0;
    if (dateUnit == deliveryDateEnum.DAY) {
      days = parseInt(dateNumber);
    } else if (dateUnit == deliveryDateEnum.WEEK) {
      days = parseInt(dateNumber) * 7;
    } else if (dateUnit == deliveryDateEnum.MONTH) {
      days = parseInt(dateNumber) * 30;
    }
    var d = new Date();
    d.setDate(d.getDate() + days);
    return d;
  }

  requestOffer(product: any, buyer: any) {
    const today = new Date();
    let ouid = `RFQ${today.getFullYear()}${('0' + (today.getMonth() + 1)).slice(
      -2
    )}-${Math.floor(1000 + Math.random() * 9000)}`;
    let sgst = product.pricing.sgst ? product.pricing.sgst : 0;
    let cgst = product.pricing.cgst ? product.pricing.cgst : 0;
    let productTotal = product.pricing.rate ? product.pricing.rate : 0;
    let grandtotal =
      productTotal + (cgst * productTotal) / 100 + (sgst * productTotal) / 100;
    let offer_data = {
      customer: buyer._id,
      generator: product.user._id,
      offer_id: ouid,
      offer_date: today,
      offer_products: [
        {
          description: `${product?.make} ${product?.model} ${product?.category?.category} ${product?.item?.category} ${product?.type}`,
          product: product,
          cgst: cgst,
          qty: 1,
          sgst: sgst,
          total: productTotal,
        },
      ],
      grand_total: grandtotal,
      product_total: productTotal,
      payment_terms: '100% Advance',
      total_sgst: sgst,
      total_cgst: cgst,
      note: '',
      status: 'requested',
      insurance: 0,
      packaging: 0,
      shipping: 0,
      discount: 0,
      planned_date: this.calculateDeliveryDate(product.delivery.deliveryTime),
    };
    console.log('offer data ', offer_data);
    this.api.createOffer(buyer.token, offer_data).subscribe(
      (resp) => {
        this.snackBar.open(
          'Offer has been requested to Seller Successfully.',
          'OK',
          {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          }
        );
      },
      (error) => {
        this.snackBar.open(
          !!error.error.error
            ? error.error.error
            : 'Error occured while requesting offer to seller.',
          'OK',
          {
            duration: 7500,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          }
        );
      }
    );
  }

  checkProfile(profile: any) {
    if (
      !!profile.contact_info &&
      !!profile.bank_info &&
      !!profile.statutory_info &&
      !!profile.company_info &&
      !!profile.contact_info.contact_person_name &&
      !!profile.contact_info.designation &&
      !!profile.contact_info.email &&
      !!profile.contact_info.phone_number &&
      !!profile.company_info.company_name &&
      !!profile.address_info &&
      !!profile.address_info.pinCode &&
      !!profile.address_info.city &&
      !!profile.address_info.state &&
      !!profile.address_info.country &&
      !!profile.address_info.textArea &&
      !!profile.address_info.landmark &&
      !!profile.address_info.locality &&
      !!profile.statutory_info.gstin &&
      !!profile.statutory_info.pan &&
      !!profile.bank_info.accNumber &&
      !!profile.bank_info.accountType &&
      !!profile.bank_info.bankName &&
      !!profile.bank_info.ifscCode
    ) {
    } else {
      this.snackBar.open(
        'Update the all required details for Business Profile first from top right corner.',
        'OK',
        {
          duration: 10000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        }
      );
    }
  }

  adminRequired() {
    let user: any = localStorage.getItem('user');
    if (user) {
      user = JSON.parse(user);
      if (!!user && user.user_type === 'admin') {
      } else {
        this.router.navigateByUrl('/homepage/stats');
      }
    }
  }

  buyerRequired() {
    let user: any = localStorage.getItem('user');
    if (user) {
      user = JSON.parse(user);
      if (!!user && user.user_type === 'buyer') {
      } else {
        this.router.navigateByUrl('/homepage/stats');
      }
    }
  }

  addToCompare(product: any) {
    let products = localStorage.getItem('products');
    if (products) {
      let productsss = JSON.parse(products);
      if (productsss.length > 0) {
        if (productsss.length === 3) {
          this.snackBar.open('You can compare only 3 Products.', 'OK', {
            duration: 7500,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        } else {
          if (productsss[0].category.category === product.category.category) {
            let productExist = false;
            productsss.forEach((element: any) => {
              if (element._id === product._id) {
                productExist = true;
              }
            });
            if (!productExist) {
              productsss.push(product);
              localStorage.setItem('products', JSON.stringify(productsss));
              this.snackBar.open(
                'Product Successfully Added to Compare',
                'OK',
                {
                  duration: 7500,
                  horizontalPosition: 'center',
                  verticalPosition: 'top',
                }
              );
            } else {
              this.snackBar.open(`Product is already in to compare.`, 'OK', {
                duration: 7500,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              });
            }
          } else {
            this.snackBar.open(
              `Select product of ${productsss[0].category.category} catgegory to compare.`,
              'OK',
              {
                duration: 7500,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              }
            );
          }
        }
      } else {
        localStorage.setItem('products', JSON.stringify([product]));
      }
    } else {
      localStorage.setItem('products', JSON.stringify([product]));
    }
  }
}
