export enum deliveryDateEnum {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month'
}

export enum userType {
    SELLER = 'seller',
    BUYER = 'buyer',
    ADMIN = 'admin'
}