import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-session-expired',
  templateUrl: './session-expired.component.html',
  styleUrls: ['./session-expired.component.scss']
})
export class SessionExpiredComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) private dlgData: any,
    private dialogRef: MatDialogRef<SessionExpiredComponent>
  ) { }

  ngOnInit(): void {
  }

  closeDiag(){
    this.dialogRef.close();
  }

}
