<div class="verify-pswd">
    <h2 mat-dialog-title class="ellipsis">
        Enter password for email: {{email}}
    </h2>

    <form>
        <mat-form-field appearance="fill" floatLabel="always" class="mfield-vk">
            <mat-label>Enter Password</mat-label>
            <input matInput type="password" [formControl]="password" placeholder="Password" required>
            <mat-error *ngIf="password.invalid && password.errors?.require">
                Password is required.
            </mat-error>
            <mat-error *ngIf="password.invalid && password.errors?.try_again">
                Try again
            </mat-error>
        </mat-form-field>

        <div>
            <mat-dialog-actions align="end">
                <!-- <button mat-button class="btn inverse" mat-dialog-close (click)="closeDialog(false)">Cancel</button> -->
                <button mat-button class="btn inverse" mat-raised-button color="primary" (click)="verifyPassword()">Verify Password</button>
            </mat-dialog-actions>
        </div>
    </form>
</div>