<div class="blog-card-holder">
  <div class="blog-spinner" *ngIf="isBlogLoading">
    <mat-progress-spinner color="primary" mode="indeterminate" [diameter]="30">
    </mat-progress-spinner>
    <span class="loader-tooltip">Loading Blog....</span>
  </div>

  <mat-card *ngFor="let blog of blog" class="main-card">
    <button mat-icon-button routerLink="/blog">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <img [src]="imageSrc" alt="" class="blog-img" />
    <mat-card-title class="blog-title">
      {{ blog.title }}
    </mat-card-title>

    <p class="blog-description">
      {{ blog.blog }}
    </p>

    <mat-card-actions>
      <button mat-stroked-button class="viewfullblogbutton" routerLink="/blog">
        Back to Main Blog Page
      </button>
    </mat-card-actions>
  </mat-card>
</div>
