<h2 mat-dialog-title class="ellipsis">
    Filters
</h2>
<div class="filters-container frow fcenter ftop">
    <div class="filters">
        <div class="each-filter" *ngFor="let filter of filters">
            <div class="each-filter-header">
                {{filter.header}}
            </div>
            <div class="each-filter-content">
                <section class="example-section">
                    <div *ngFor="let item of filter.content"><mat-checkbox (change)="addFilter($event, filter.header)" [value]="item" [checked]="appliedFilters[filter.header].indexOf(item) !== -1">{{item}}</mat-checkbox><br/></div>
                </section>
            </div>
            <mat-divider></mat-divider>
        </div>
    </div>
</div>
<mat-dialog-actions align="end">
    <button mat-button class="btn inverse" mat-dialog-close (click)="closeDialog()">Close</button>
    <button mat-button class="btn inverse" mat-raised-button color="primary" (click)="applyFilterActions()">Apply</button>
</mat-dialog-actions>
