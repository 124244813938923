<h2 mat-dialog-title class="ellipsis">
    Product Comparison
</h2>
<table mat-table matSort [dataSource]="profileDetails" multiTemplateDataRows class="demo-table" [class.isMobile]="'isMobile'">
    <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns; let index">
        <td mat-cell *matCellDef="let element" [class]="column === 'def'? 'column-header-profile' : ''">
            <span *ngIf="column === 'def'"><b>{{element['p0']}}</b></span>
            <img *ngIf="column === 'Details of First Product'" class="display-img" [src]="backendIp + 'product/' +element['p1']['id'] +'/front'" alt="No Image is available">
            <img *ngIf="column === 'Details of Second Product'" class="display-img" [src]="backendIp + 'product/' +element['p2']['id'] +'/front'" alt="No Image is available">
            <img *ngIf="column === 'Details of Third Product'" class="display-img" [src]="backendIp + 'product/' +element['p3']['id'] +'/front'" alt="No Image is available">
        </td>
    </ng-container>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;">
    </tr>
</table>

<table mat-table matSort [dataSource]="requestOffers" multiTemplateDataRows class="demo-table" [class.isMobile]="'isMobile'">
    <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns">
        <td mat-cell *matCellDef="let element" [class]="column === 'def'? 'column-header-profile' : ''">
            <span *ngIf="column === 'def'"><b>{{element['p0']}}</b></span>
            <button *ngIf="column === 'Details of First Product'" mat-button class="btn inverse" mat-raised-button color="primary" (click)="requestOffer(products[0])">Request Offer</button>
            <button *ngIf="column === 'Details of Second Product'" mat-button class="btn inverse" mat-raised-button color="primary" (click)="requestOffer(products[1])">Request Offer</button>
            <button *ngIf="column === 'Details of Third Product'" mat-button class="btn inverse" mat-raised-button color="primary" (click)="requestOffer(products[2])">Request Offer</button>
        </td>
    </ng-container>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;">
    </tr>
</table>

<table mat-table matSort [dataSource]="basicDetails" multiTemplateDataRows class="mat-elevation-z8 demo-table" [class.isMobile]="'isMobile'">
    <!-- <ng-container matColumnDef="abc">
        <th mat-header-cell *matHeaderCellDef > abc </th>
        <td mat-cell *matCellDef="let element">
            <span>hoy kai</span>
        </td>
    </ng-container> -->

    <!-- <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number"> {{column === 'def' ? '' : column}} </th>
        <td mat-cell *matCellDef="let element">
            <span *ngIf="column === 'def'">{{element[0]}}</span>
            <div class="mobile-label">{{column.toUpperCase()}}: </div>
            <span *ngIf="column !== 'def'">{{element['category']['category']}}</span>
        </td>
    </ng-container> -->


    <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number" class="main-header"> {{column === 'def' ? '' : column}} </th>
        <td mat-cell *matCellDef="let element" [class]="column === 'def'? 'column-header' : ''">
            <span *ngIf="column === 'def'"><b>{{element['p0']}}</b></span>
            <span *ngIf="column === 'Details of First Product'">{{element['p1']}}</span>
            <span *ngIf="column === 'Details of Second Product'">{{element['p2']}}</span>
            <span *ngIf="column === 'Details of Third Product'">{{element['p3']}}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="second-header">
        <th mat-header-cell *matHeaderCellDef colspan="4" class="second-header"> Basic Product Details </th>
    </ng-container>
    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <!-- <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <app-product-form class="example-element-detail" [product]="element" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"></app-product-form>
        </td>
    </ng-container> -->
    <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns" class="example-first-header-row">
    </tr> -->
    <tr mat-header-row *matHeaderRowDef="['second-header']" class="example-second-header-row">
    </tr>

    <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr> -->
    <tr mat-row *matRowDef="let element; columns: displayedColumns;">
    </tr>





    <!-- <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> -->
  
      <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->
</table>

<table mat-table matSort [dataSource]="powerSupply" multiTemplateDataRows class="demo-table" [class.isMobile]="'isMobile'">
    <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns">
        <td mat-cell *matCellDef="let element" [class]="column === 'def'? 'column-header' : ''">
            <span *ngIf="column === 'def'"><b>{{element['p0']}}</b></span>
            <span *ngIf="column === 'Details of First Product'">{{element['p1']}}</span>
            <span *ngIf="column === 'Details of Second Product'">{{element['p2']}}</span>
            <span *ngIf="column === 'Details of Third Product'">{{element['p3']}}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="second-header">
        <th mat-header-cell *matHeaderCellDef colspan="4" class="second-header"> Power Supply </th>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="['second-header']" class="example-second-header-row">
    </tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;">
    </tr>
</table>
<table mat-table matSort [dataSource]="outputSignals" multiTemplateDataRows class="demo-table" [class.isMobile]="'isMobile'">
    <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns">
        <td mat-cell *matCellDef="let element" [class]="column === 'def'? 'column-header' : ''">
            <span *ngIf="column === 'def'"><b>{{element['p0']}}</b></span>
            <span *ngIf="column === 'Details of First Product'">{{element['p1']}}</span>
            <span *ngIf="column === 'Details of Second Product'">{{element['p2']}}</span>
            <span *ngIf="column === 'Details of Third Product'">{{element['p3']}}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="second-header">
        <th mat-header-cell *matHeaderCellDef colspan="4" class="second-header"> Output Signals </th>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="['second-header']" class="example-second-header-row">
    </tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;">
    </tr>
</table>
<table mat-table matSort [dataSource]="communicationSW" multiTemplateDataRows class="demo-table" [class.isMobile]="'isMobile'">
    <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns">
        <td mat-cell *matCellDef="let element" [class]="column === 'def'? 'column-header' : ''">
            <span *ngIf="column === 'def'"><b>{{element['p0']}}</b></span>
            <span *ngIf="column === 'Details of First Product'">{{element['p1']}}</span>
            <span *ngIf="column === 'Details of Second Product'">{{element['p2']}}</span>
            <span *ngIf="column === 'Details of Third Product'">{{element['p3']}}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="second-header">
        <th mat-header-cell *matHeaderCellDef colspan="4" class="second-header"> Communication Software </th>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="['second-header']" class="example-second-header-row">
    </tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;">
    </tr>
</table>
<table mat-table matSort [dataSource]="transmitter" multiTemplateDataRows class="demo-table" [class.isMobile]="'isMobile'">
    <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns">
        <td mat-cell *matCellDef="let element" [class]="column === 'def'? 'column-header' : ''">
            <span *ngIf="column === 'def'"><b>{{element['p0']}}</b></span>
            <span *ngIf="column === 'Details of First Product'">{{element['p1']}}</span>
            <span *ngIf="column === 'Details of Second Product'">{{element['p2']}}</span>
            <span *ngIf="column === 'Details of Third Product'">{{element['p3']}}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="second-header">
        <th mat-header-cell *matHeaderCellDef colspan="4" class="second-header"> Transmitter </th>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="['second-header']" class="example-second-header-row">
    </tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;">
    </tr>
</table>
<table mat-table matSort [dataSource]="enclosure" multiTemplateDataRows class="demo-table" [class.isMobile]="'isMobile'">
    <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns">
        <td mat-cell *matCellDef="let element" [class]="column === 'def'? 'column-header' : ''">
            <span *ngIf="column === 'def'"><b>{{element['p0']}}</b></span>
            <span *ngIf="column === 'Details of First Product'">{{element['p1']}}</span>
            <span *ngIf="column === 'Details of Second Product'">{{element['p2']}}</span>
            <span *ngIf="column === 'Details of Third Product'">{{element['p3']}}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="second-header">
        <th mat-header-cell *matHeaderCellDef colspan="4" class="second-header"> Enclosure </th>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="['second-header']" class="example-second-header-row">
    </tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;">
    </tr>
</table>
<table mat-table matSort [dataSource]="installation" multiTemplateDataRows class="demo-table" [class.isMobile]="'isMobile'">
    <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns">
        <td mat-cell *matCellDef="let element" [class]="column === 'def'? 'column-header' : ''">
            <span *ngIf="column === 'def'"><b>{{element['p0']}}</b></span>
            <span *ngIf="column === 'Details of First Product'">{{element['p1']}}</span>
            <span *ngIf="column === 'Details of Second Product'">{{element['p2']}}</span>
            <span *ngIf="column === 'Details of Third Product'">{{element['p3']}}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="second-header">
        <th mat-header-cell *matHeaderCellDef colspan="4" class="second-header"> Installation </th>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="['second-header']" class="example-second-header-row">
    </tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;">
    </tr>
</table>
<table mat-table matSort [dataSource]="cataloges" multiTemplateDataRows class="demo-table" [class.isMobile]="'isMobile'">
    <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns">
        <td mat-cell *matCellDef="let element" [class]="column === 'def'? 'column-header' : ''">
            <span *ngIf="column === 'def'"><b>{{element['p0']}}</b></span>
            <a *ngIf="column === 'Details of First Product'" href="{{backendIp}}product/{{element['p1']['id']}}/{{element['p1']['endpoint']}}" class="btn clss" target="_self" download>{{element['p1']['label']}}</a>
            <a *ngIf="column === 'Details of Second Product'" href="{{backendIp}}product/{{element['p2']['id']}}/{{element['p2']['endpoint']}}" class="btn clss" target="_self" download>{{element['p2']['label']}}</a>
            <a *ngIf="column === 'Details of Third Product'" href="{{backendIp}}product/{{element['p3']['id']}}/{{element['p3']['endpoint']}}" class="btn clss" target="_self" download>{{element['p3']['label']}}</a>
        </td>
    </ng-container>
    <ng-container matColumnDef="second-header">
        <th mat-header-cell *matHeaderCellDef colspan="4" class="second-header"> Cataloges & Certificates </th>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="['second-header']" class="example-second-header-row">
    </tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;">
    </tr>
</table>

