<section id="hero" class="d-flex align-items-center">
  <!-- <app-carousel class="app-car" [images]="images"></app-carousel> -->

  <div class="container">
    <div class="row">
      <div
        class="hero-text-container d-flex flex-column justify-content-center order-2 order-lg-1"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        <h1>INNOVATIVE SOLUTION FOR <br />INSTRUMENTATION MANUFACTURERS</h1>
        <br />
        <!-- <h2>Optimize Your Procurement Processes as a Buyer</h2> -->
        <ul class="home-unorderedlist">
          <li>List Your Products</li>
          <li>Grow Reseller network</li>
          <li>Connect with customers</li>
        </ul>

        <div
          class="d-flex justify-content-center justify-content-lg-start row btns"
        >
          <a
            href="https://www.instrumentflow.com/login"
            class="btn-get-started scrollto"
            >Get Started</a
          >
          <a
            href="http://www.instrumentflow.com/blog"
            class="glightbox btn-watch-video"
            ><i class="bi bi-play-circle"></i
            ><span>Visit Knowledge Portal</span></a
          >
        </div>

        <!--<div class="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">-->
        <!--  <img src="../../../../assets/applogo/undraw_product_iteration_kjok.svg" class="img-fluid animated" alt="">-->
        <!--</div>-->
      </div>
    </div>
    <!-- 
    <div class="image row-2">
      <img src="../../../../assets/hero.svg" alt="" />
    </div> -->
  </div>
</section>

<!-- Original Code below. Do Not Change anything -->

<div class="frow fsb header">
  <div class="header-logo"></div>

  <div class="search frow">
    <mat-form-field appearance="outline" class="first-mat">
      <mat-label>Category</mat-label>
      <mat-select
        [formControl]="selectedCategory"
        (valueChange)="selectCategory($event)"
      >
        <mat-option [value]="'All'"> All </mat-option>
        <mat-option *ngFor="let category of categories" [value]="category._id">
          {{ category.category }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="second-mat">
      <mat-label>Search Product</mat-label>
      <input type="text" matInput [formControl]="searchProduct" />
      <button
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="searchInProduct()"
      >
        <mat-icon>search</mat-icon>
      </button>
    </mat-form-field>

    <div
      class="mobile-filters"
      [ngClass]="
        filtersVisibility ? 'mobile-filters-visible' : 'mobile-filters-hidden'
      "
    >
      <mat-icon (click)="openMobileFilters()">filter_list</mat-icon>
    </div>
  </div>
  <div class="header-logo"></div>
</div>
<div class="frow fsb hehe" *ngIf="!isLoading">
  <div
    class="filters-container frow fcenter ftop"
    [ngClass]="filtersVisibility ? 'filters-visible' : 'filters-hidden'"
  >
    <div class="filters">
      <div class="each-filter" *ngFor="let filter of filters">
        <div class="each-filter-header">
          {{ filter.header }}
        </div>
        <div class="each-filter-content">
          <section class="example-section">
            <div *ngFor="let item of filter.content">
              <mat-checkbox
                (change)="addFilter($event, filter.header)"
                [value]="item"
                >{{ item }}</mat-checkbox
              ><br />
            </div>
          </section>
        </div>
        <mat-divider></mat-divider>
      </div>
    </div>
  </div>
  <div class="basic-products" *ngIf="!noProducts">
    <div id="basic-products">
      <div class="each-product" *ngFor="let product of products">
        <div class="btnss">
          <!-- <div class="btn call"> -->
            <mat-icon (click)="callUs()">call</mat-icon>
          <!-- </div> -->
          <!-- <div class="btn add-to-compare"> -->
            <mat-icon (click)="compareProducts(product)" matTooltip="Add to Compare">difference</mat-icon>
          <!-- </div> -->
        </div>
        <div class="product-img">
          <img
            src="{{ backendIp }}product/{{ product['_id'] }}/front"
            alt="No Images available"
          />
        </div>
        <div class="pad-5">
          <div class="heading">
            {{ product.category.category }} {{ product.item.item }}
            {{ product.item.product_type }}
          </div>
          <div class="pad-5"></div>
          <div
            class="pad-5 ellipsis"
            [matTooltip]="product?.additional_details?.description"
          >
            {{ product?.additional_details?.description }}
          </div>
          <div class="pad-1">
            <span class="link cpointer" (click)="openFullSpec(product)"
              >View Full Specifications</span
            >
          </div>
          <div class="pad-1">
            <mat-chip-list class="demo-button">
              <button mat-raised-button
                color="primary"
                selected
                class="cpointer"
                (click)="requestOffer(product)"
                >Request Offer</button
              >
             
              
            </mat-chip-list>
          </div>
        </div>
      </div>
    </div>
    <div class="basic-products" *ngIf="noProducts">
      <h1>No Products are available.</h1>
    </div>
  </div>
</div>
<div class="table-loader paginated" *ngIf="isPaginatedLoading">
  <mat-progress-spinner color="primary" mode="indeterminate" [diameter]="30">
  </mat-progress-spinner>
  <span class="loader-tooltip">Loading Products....</span>
</div>
<div class="table-loader" *ngIf="isLoading">
  <mat-progress-spinner
    color="primary"
    mode="indeterminate"
    [diameter]="diameter"
  >
  </mat-progress-spinner>
  <span class="loader-tooltip">Loading Products....</span>
</div>
