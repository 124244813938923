<mat-card>
    <form>
        <mat-card-title class="mat-card-title">
            <h2>Change Password</h2>
        </mat-card-title>
        <div class="fcol m-fcol m-fw">
            <div class="fcol relative fx m-fw">
                <mat-form-field appearance="fill" floatLabel="always">
                    <mat-label>Current Password</mat-label>
                    <input matInput type="password" [formControl]="currentPassword" placeholder="Current Password">
                    <mat-error *ngIf="currentPassword.invalid">
                        <div *ngIf="currentPassword.errors?.required">required</div>
                        <div *ngIf="currentPassword.errors?.minlength">Length of password should be 8.</div>
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" floatLabel="always">
                    <mat-label>New Password</mat-label>
                    <input matInput type="password" [formControl]="newPassword" placeholder="New Password">
                    <mat-error *ngIf="newPassword.invalid">
                        <div *ngIf="newPassword.errors?.required">required</div>
                        <div *ngIf="newPassword.errors?.minlength">Length of password should be 8.</div>
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" floatLabel="always">
                    <mat-label>Confirm Password</mat-label>
                    <input matInput type="password" [formControl]="confirmPassword" placeholder="Confirm Password">
                    <mat-error *ngIf="confirmPassword.invalid">
                        <div *ngIf="confirmPassword.errors?.required">required</div>
                        <div *ngIf="confirmPassword.errors?.minlength">Length of password should be 8.</div>
                    </mat-error>
                </mat-form-field>
            </div>
            
        </div>
    </form>
    <div class="profile-info__actions">
        <mat-dialog-actions align="end">
            <button mat-button class="btn inverse" mat-dialog-close (click)="resetForm()" *ngIf="!dlgData.user.reset">Cancel</button>
            <button mat-button class="btn inverse" mat-raised-button color="primary" (click)="changePassword()" [disabled]="grp.invalid">Change Password</button>
        </mat-dialog-actions>
    </div>
</mat-card>