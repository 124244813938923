<div class="about">
    <mat-card-title class="mat-card-title">
        <h2>CONTACT US</h2>
    </mat-card-title>
    <div class="about-content">
        <p>Ensqual Technosolutions Private Limited.</p>
        <p>1005-1006, Titaanium Business Hub,</p>
        <p>Bhimrad, Althan.</p>
        <p>Surat, INDIA -395007</p>
        <br/><br/>
        <p>+91 940 923 930 5</p>
        <p>welcome@instrumentflow.com</p>
    </div>
</div>