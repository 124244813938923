import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BACKEND_IP } from '../constants';

var default_headers = new HttpHeaders();
default_headers = default_headers.set("Content-Type", "application/json");
default_headers = default_headers.append("Access-Control-Allow-Origin", "*");

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private apiUrl = BACKEND_IP;

  constructor(private http:HttpClient) { }

  blogs() {
    var blogsurl = this.apiUrl + "getBlogs"
    return this.http.get(blogsurl)
  }

  updateBlog(token: any, body:any, blogId:any) {
    var auth_header = "Bearer " + token;
    var updateBlog = this.apiUrl + "editblog/" + blogId;

    var new_headers = new HttpHeaders();
    new_headers = new_headers.append("Access-Control-Allow-Origin", "*");
    var headers = new_headers.append("Authorization", auth_header);
    return this.http.post(updateBlog, body, {headers})
  }

  updateBlogImg(token:any,image:Blob ,blogId: any): Observable<any> {
    var auth_header = "Bearer " + token
    var blogsurl = this.apiUrl + "blogimg"
    var form_data = new FormData();
    var new_headers = new HttpHeaders();
    new_headers = new_headers.append("Access-Control-Allow-Origin", "*");
    var headers = new_headers.append("Authorization", auth_header);
    form_data.append('blog_img', image);
    form_data.append('blogId', blogId);
    return this.http.post<any>(blogsurl, form_data, {headers:headers})  
  }

  createBlog(body:any, token:any) {
    var blogsurl = this.apiUrl + "crblgt"
    var auth_header = "Bearer " + token;
    var headers = default_headers.append("Authorization", auth_header);
    return this.http.post(blogsurl,body, {headers: headers} )
  }

  findBlogById(id:any) {
    var blogurl = this.apiUrl + "findBlogById"
    return this.http.post(blogurl, {id:id})
  }
  
  loginUser(user: any): Observable<any> {
    var loginUrl = this.apiUrl + "user/login";
    return this.http.post<any>(loginUrl, user, { headers:default_headers });
  }

  logoutUser(token: any): Observable<any> {
    var logoutUrl = this.apiUrl + "user/logout";
    var auth_header = "Bearer " + token;
    var headers = default_headers.append("Authorization", auth_header);
    return this.http.post<any>(logoutUrl, {},{headers});
  }

  forgotPassword(data: any): Observable<any>{
    var fpUrl = this.apiUrl + "user/forgotPassword";
    return this.http.post<any>(fpUrl, data, { headers:default_headers });
  }

  signinUser(user: any): Observable<any> {
    var loginUrl = this.apiUrl + "user/signin";
    return this.http.post<any>(loginUrl, user, { headers:default_headers });
  }

  getUserProfile(token: any): Observable<any> {
    var auth_header = "Bearer " + token;
    var profileUrl = this.apiUrl + "user/profile";
    var headers = default_headers.append("Authorization", auth_header);
    return this.http.get<any>(profileUrl, {headers});
  }

  getMembers(token: any): Observable<any> {
    var auth_header = "Bearer " + token;
    var teamUrl = this.apiUrl + "team/members";
    var headers = default_headers.append("Authorization", auth_header);
    return this.http.get<any>(teamUrl, {headers});
  }

  getRequests(token: any, status: string): Observable<any> {
    var auth_header = "Bearer " + token;
    var requestsUrl = this.apiUrl + "user/requests";
    var headers = default_headers.append("Authorization", auth_header);
    return this.http.post<any>(requestsUrl, {status}, {headers} );
  }

  getParentSellers(token: any, sellers: any): Observable<any> {
    var auth_header = "Bearer " + token;
    var parentSellersUrl = this.apiUrl + "parent/sellers";
    var headers = default_headers.append("Authorization", auth_header);
    return this.http.post<any>(parentSellersUrl, {sellers}, {headers} );
  }

  updateStatus(token: any, data:any): Observable<any>{
    var auth_header = "Bearer " + token;
    var updateStatusUrl = this.apiUrl + "user/updatestatus";
    var headers = default_headers.append("Authorization", auth_header);
    return this.http.patch<any>(updateStatusUrl, data, {headers} );
  }

  updateProfilePicture(token: any, image:Blob): Observable<any>{
    var auth_header = "Bearer " + token;
    var updateProfileUrl = this.apiUrl + "user/profilepicture";
    var form_data = new FormData();
    var new_headers = new HttpHeaders();
    new_headers = new_headers.append("Access-Control-Allow-Origin", "*");
    var headers = new_headers.append("Authorization", auth_header);
    form_data.append('profile_picture', image);
    return this.http.post<any>(updateProfileUrl, form_data, {headers} );
  }

  updateCompanyLogo(token: any, image:Blob): Observable<any>{
    var auth_header = "Bearer " + token;
    var updateCompanyLogo = this.apiUrl + "user/companyLogo";
    var form_data = new FormData();
    var new_headers = new HttpHeaders();
    new_headers = new_headers.append("Access-Control-Allow-Origin", "*");
    var headers = new_headers.append("Authorization", auth_header);
    form_data.append('company_logo', image);
    return this.http.post<any>(updateCompanyLogo, form_data, {headers} );
  }

  updateRequestDatasheet(token: any, image:Blob, request_id:any): Observable<any>{
    var auth_header = "Bearer " + token;
    var updateCompanyLogo = this.apiUrl + "request/datasheet";
    var form_data = new FormData();
    var new_headers = new HttpHeaders();
    new_headers = new_headers.append("Access-Control-Allow-Origin", "*");
    var headers = new_headers.append("Authorization", auth_header);
    form_data.append('datasheet', image);
    form_data.append('request_id', request_id);
    return this.http.post<any>(updateCompanyLogo, form_data, {headers} );
  }

  updateOffer1Datasheet(token: any, image:Blob, offer_id:any): Observable<any>{
    var auth_header = "Bearer " + token;
    var updateOffer1Datasheet = this.apiUrl + "offers/offer1datasheet";
    var form_data = new FormData();
    var new_headers = new HttpHeaders();
    new_headers = new_headers.append("Access-Control-Allow-Origin", "*");
    var headers = new_headers.append("Authorization", auth_header);
    form_data.append('offer1_datasheet', image);
    form_data.append('offer_id', offer_id);
    return this.http.post<any>(updateOffer1Datasheet, form_data, {headers} );
  }

  updateOffer1attachment(token: any, image:Blob, offer_id:any): Observable<any>{
    var auth_header = "Bearer " + token;
    var updateOffer1Attachment = this.apiUrl + "offers/offer1attachment";
    var form_data = new FormData();
    var new_headers = new HttpHeaders();
    new_headers = new_headers.append("Access-Control-Allow-Origin", "*");
    var headers = new_headers.append("Authorization", auth_header);
    form_data.append('offer1_attachment', image);
    form_data.append('offer_id', offer_id);
    return this.http.post<any>(updateOffer1Attachment, form_data, {headers} );
  }

  updateOffer2Datasheet(token: any, image:Blob, offer_id:any): Observable<any>{
    var auth_header = "Bearer " + token;
    var updateOffer2Datasheet = this.apiUrl + "offers/offer2datasheet";
    var form_data = new FormData();
    var new_headers = new HttpHeaders();
    new_headers = new_headers.append("Access-Control-Allow-Origin", "*");
    var headers = new_headers.append("Authorization", auth_header);
    form_data.append('offer2_datasheet', image);
    form_data.append('offer_id', offer_id);
    return this.http.post<any>(updateOffer2Datasheet, form_data, {headers} );
  }

  updateOffer2attachment(token: any, image:Blob, offer_id:any): Observable<any>{
    var auth_header = "Bearer " + token;
    var updateOffer2Attachment = this.apiUrl + "offers/offer2attachment";
    var form_data = new FormData();
    var new_headers = new HttpHeaders();
    new_headers = new_headers.append("Access-Control-Allow-Origin", "*");
    var headers = new_headers.append("Authorization", auth_header);
    form_data.append('offer2_attachment', image);
    form_data.append('offer_id', offer_id);
    return this.http.post<any>(updateOffer2Attachment, form_data, {headers} );
  }

  updateOffer3Datasheet(token: any, image:Blob, offer_id:any): Observable<any>{
    var auth_header = "Bearer " + token;
    var updateOffer3Datasheet = this.apiUrl + "offers/offer3datasheet";
    var form_data = new FormData();
    var new_headers = new HttpHeaders();
    new_headers = new_headers.append("Access-Control-Allow-Origin", "*");
    var headers = new_headers.append("Authorization", auth_header);
    form_data.append('offer3_datasheet', image);
    form_data.append('offer_id', offer_id);
    return this.http.post<any>(updateOffer3Datasheet, form_data, {headers} );
  }

  updateOffer3attachment(token: any, image:Blob, offer_id:any): Observable<any>{
    var auth_header = "Bearer " + token;
    var updateOffer3Attachment = this.apiUrl + "offers/offer3attachment";
    var form_data = new FormData();
    var new_headers = new HttpHeaders();
    new_headers = new_headers.append("Access-Control-Allow-Origin", "*");
    var headers = new_headers.append("Authorization", auth_header);
    form_data.append('offer3_attachment', image);
    form_data.append('offer_id', offer_id);
    return this.http.post<any>(updateOffer3Attachment, form_data, {headers} );
  }

  generateCompareOffers(token:any, data:any): Observable<any>{
    var auth_header = "Bearer " + token;
    var createCompareOfferUrl = this.apiUrl + "compare/offer";
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.post<any>(createCompareOfferUrl, data, {headers} );
  }

  getSkill(token:any): Observable<any>{
    var auth_header = "Bearer " + token;
    var userSkillUrl = this.apiUrl + "user/skill";
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.get<any>(userSkillUrl, {headers} );
  }

  upsertSkills(token:any, skills:any): Observable<any>{
    var auth_header = "Bearer " + token;
    var upsertSkillsUrl = this.apiUrl + "user/skill/upsert";
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.post<any>(upsertSkillsUrl, skills, {headers} );
  }

  getAllSkill(token:any): Observable<any>{
    var auth_header = "Bearer " + token;
    var skillUrl = this.apiUrl + "skill";
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.get<any>(skillUrl, {headers} );
  }

  deleteUserSkill(token:any, skill_id:any): Observable<any>{
    var auth_header = "Bearer " + token;
    var deleteSkillUrl = this.apiUrl + "user/skill/" + skill_id;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.delete<any>(deleteSkillUrl, {headers} );
  }

  getAllCategories(): Observable<any>{
    var categoriesUrl = this.apiUrl + "productCategory";
    return this.http.get<any>(categoriesUrl, {headers: default_headers} );
  }

  verifyOtp(email:string, otp:number, type:string): Observable<any>{
    var verifyOtpUrl = this.apiUrl + "user/verifyotp";
    return this.http.post<any>(verifyOtpUrl, {email, otp, type}, { headers:default_headers });
  }

  resendOtp(email:string, type:string): Observable<any>{
    var verifyOtpUrl = this.apiUrl + "user/resentotp";
    return this.http.post<any>(verifyOtpUrl, {email, type}, { headers:default_headers });
  }

  getProductItems(token:any, productType:string): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var productItemsUrl = `${this.apiUrl}productitems/${productType}`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.get<any>(productItemsUrl, {headers});
  }

  createProduct(token:any, product_details:any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var createProductUrl = `${this.apiUrl}product`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.post<any>(createProductUrl, product_details, {headers} );
  }

  createRequest(token:any, request_details:any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var createRequestUrl = `${this.apiUrl}request`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.post<any>(createRequestUrl, request_details, {headers} );
  }

  getProducts(token:any, params={}): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var getProductUrl = `${this.apiUrl}products`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.get<any>(getProductUrl, {headers: headers, params: params} );
  }

  getProductRels(token:any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var getRelProductUrl = `${this.apiUrl}relational/products`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.get<any>(getRelProductUrl, {headers} );
  }

  getBasicProducts(category: any, page: number, pageSize: number): Observable<any>{
    var getBasicProductUrl = `${this.apiUrl}basicproducts?category=${category}&page=${page}&pageSize=${pageSize}`;
    // var default_params = new HttpParams();
    // default_params.append("category", category);
    // console.log("default params ", default_params);
    return this.http.get<any>(getBasicProductUrl, {headers: default_headers} );
  }

  getCollectiveOffersandOrders(token: any, type: any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var barChartUrl = `${this.apiUrl}collective/offers/orders`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.get<any>(barChartUrl, {headers: headers, params: {type}} );
  }

  getSortedOffersandOrders(token: any, data: any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var barChartUrl = `${this.apiUrl}sorted/offers/orders`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.post<any>(barChartUrl, data, {headers: headers} );
  }

  getSortedOrderValues(token: any, data: any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var barChartUrl = `${this.apiUrl}sorted/ordervalues`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.post<any>(barChartUrl, data, {headers: headers} );
  }

  compareProducts(data: any): Observable<any>{
    var getBasicProductUrl = `${this.apiUrl}compareproducts`;
    // var default_params = new HttpParams();
    // default_params.append("category", category);
    // console.log("default params ", default_params);
    return this.http.post<any>(getBasicProductUrl, data, {headers: default_headers} );
  }

  updateProduct(token:any, additional_product_details:any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var updateProductUrl = `${this.apiUrl}updateProduct`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.patch<any>(updateProductUrl, additional_product_details, {headers} );
  }

  getProfile(token:any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var getProfileUrl = `${this.apiUrl}user/profile`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.get<any>(getProfileUrl, {headers} );
  }

  updateProfile(token:any, profile_details:any): Observable<any>{
    var auth_header = "Bearer " + token;
    var updateProfileUrl = this.apiUrl + "user/updateprofile";
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.patch<any>(updateProfileUrl, profile_details, {headers} );
  }

  verifyPassword(token:any, creds:any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var verifyPasswordUrl = `${this.apiUrl}user/verifypassword`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.post<any>(verifyPasswordUrl, creds, {headers} );
  }

  getProduct(token:any, product_id:any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var getProductUrl = `${this.apiUrl}product/${product_id}`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.get<any>(getProductUrl, {headers} );
  }

  changePassword(token:any, creds:any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var changePasswordUrl = `${this.apiUrl}user/changepassword`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.post<any>(changePasswordUrl, creds, {headers} );
  }

  addCustomer(token:any, data:any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var addCustomerUrl = `${this.apiUrl}user/addCustomer`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.post<any>(addCustomerUrl, data, {headers} );
  }

  getCustomers(token:any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var getCustomersUrl = `${this.apiUrl}user/customers`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.get<any>(getCustomersUrl, {headers} );
  }

  createRazorOrderId(token:any, payload:any): Observable<any>{
    var auth_header = "Bearer " + token;
    var createRazorOrderIdUrl = this.apiUrl + "create/paymentId";
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.post<any>(createRazorOrderIdUrl, payload, {headers} );
  }

  updatePayment(token:any, payment_details:any): Observable<any>{
    var auth_header = "Bearer " + token;
    var updatePaymentUrl = this.apiUrl + "payment";
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.patch<any>(updatePaymentUrl, payment_details, {headers} );
  }

  getBuyerRequests(token:any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var getBuyerRequestsUrl = `${this.apiUrl}requests`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.get<any>(getBuyerRequestsUrl, {headers} );
  }

  getCustomer(token:any, mail:string): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var getCustomerUrl = `${this.apiUrl}user/findcustomer/${mail}`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.get<any>(getCustomerUrl, {headers} );
  }

  createOffer(token:any, offer_details:any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var createOfferUrl = `${this.apiUrl}offer`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.post<any>(createOfferUrl, offer_details, {headers} );
  }

  getOffers(token:any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var getOfferUrl = `${this.apiUrl}offers`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.get<any>(getOfferUrl, {headers} );
  }

  getInvoices(token:any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var getInvoicesUrl = `${this.apiUrl}invoices`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.get<any>(getInvoicesUrl, {headers} );
  }

  getRequestedOffers(token:any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var getOfferUrl = `${this.apiUrl}requestedoffers`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.get<any>(getOfferUrl, {headers} );
  }

  getCuurentUser(token:any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var getCurrentUserUrl = `${this.apiUrl}user/me`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.get<any>(getCurrentUserUrl, {headers} );
  }

  updateOffer(token:any, offer_details:any): Observable<any>{
    var auth_header = "Bearer " + token;
    var updateOfferUrl = this.apiUrl + "updateOffer";
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.patch<any>(updateOfferUrl, offer_details, {headers} );
  }
  
  confirmOrder(token:any, order_details:any): Observable<any>{
    var auth_header = "Bearer " + token;
    var confirmOrderUrl = this.apiUrl + "confirmOrder";
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.post<any>(confirmOrderUrl, order_details, {headers} );
  }

  updateOrder(token:any, order_details:any): Observable<any>{
    var auth_header = "Bearer " + token;
    var updateOrderUrl = this.apiUrl + "updateOrder";
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.patch<any>(updateOrderUrl, order_details, {headers} );
  }

  sendOffer(token:any, element:any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var sendOfferUrl = `${this.apiUrl}sendoffer`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.post<any>(sendOfferUrl, {element}, {headers} );
  }

  downloadOffer(token:any, element:any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var downloadOfferUrl = `${this.apiUrl}downloadOffer`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.post<any>(downloadOfferUrl, {element}, {headers} );
  }

  downloadOrder(token:any, element:any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var downloadOrderUrl = `${this.apiUrl}downloadOrder`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.post<any>(downloadOrderUrl, {element}, {headers} );
  }

  downloadInvoice(token:any, invoice_id:any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var downloadInvoiceUrl = `${this.apiUrl}downloadInvoice`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.post<any>(downloadInvoiceUrl, {invoice_id}, {headers} );
  }

  sendOrder(token:any, element:any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var sendOrderUrl = `${this.apiUrl}sendorder`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.post<any>(sendOrderUrl, {element}, {headers} );
  }

  updateProductImages(token: any, image: any, section: string, product_id:string): Observable<any>{
    var auth_header = "Bearer " + token;
    var updateLeftImageUrl = `${this.apiUrl}product/${section}`;
    var form_data = new FormData();
    var new_headers = new HttpHeaders();
    new_headers = new_headers.append("Access-Control-Allow-Origin", "*");
    var headers = new_headers.append("Authorization", auth_header);
    form_data.append(section, image);
    form_data.append('product_id', product_id);
    return this.http.post<any>(updateLeftImageUrl, form_data, {headers} );
  }

  acceptOffer(token:any, element:any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var acceptOfferUrl = `${this.apiUrl}acceptoffer`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.post<any>(acceptOfferUrl, {element}, {headers} );
  }

  getOrders(token:any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var getOrderUrl = `${this.apiUrl}orders`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.get<any>(getOrderUrl, {headers} );
  }

  createReseller(token:any, data:any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var createResellerUrl = `${this.apiUrl}user/create/reseller`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.post<any>(createResellerUrl, data, {headers} );
  }

  verifyUser(data:any): Observable<any>{
    var verifyUserUrl = `${this.apiUrl}user/verify`;
    return this.http.post<any>(verifyUserUrl, data, {headers:default_headers} );
  }

  getReseller(token:any, mail:string): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var getResellerUrl = `${this.apiUrl}user/findreseller/${mail}`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.get<any>(getResellerUrl, {headers} );
  }

  fetchResellers(token:any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var allResellersUrl = `${this.apiUrl}user/resellers`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.get<any>(allResellersUrl, {headers} );
  }

  getSellers(token:any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var resellersUrl = `${this.apiUrl}sellers`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.get<any>(resellersUrl, {headers} );
  }

  getBuyers(token:any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var buyersUrl = `${this.apiUrl}buyers`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.get<any>(buyersUrl, {headers} );
  }

  getUnits(): Observable<any>{
    var getUnitsUrl = `${this.apiUrl}units`;
    return this.http.get<any>(getUnitsUrl, {headers: default_headers} );
  }

  getUnitsEach(type:any):Observable<any> {
    var getUnitsUrl = `${this.apiUrl}units/${type}`;
    return this.http.get<any>(getUnitsUrl, {headers: default_headers} );
  }

  getBasicProduct(product_id:any): Observable<any>{
    var getBasicProductUrl = `${this.apiUrl}basicproduct/${product_id}`;
    return this.http.get<any>(getBasicProductUrl, {headers: default_headers} );
  }

  allocateProducts(token:any, data:any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var allocateProductsUrl = `${this.apiUrl}allocate/product`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.post<any>(allocateProductsUrl, data, {headers} );
  }

  getAllocatedProducts(token:any, child_seller_id:string): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var getAllocatedProductUrl = `${this.apiUrl}allocated/product/${child_seller_id}`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.get<any>(getAllocatedProductUrl, {headers} );
  }

  revokeProducts(token:any, data:any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var revokeProductsUrl = `${this.apiUrl}revoke/product`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.post<any>(revokeProductsUrl, data, {headers} );
  }

  getCommunications(token:any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var getCommunsUrl = `${this.apiUrl}communications`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.get<any>(getCommunsUrl, {headers} );
  }

  deactivateReseller(token:any, data:any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var deactivateResellerUrl = `${this.apiUrl}deactivate/reseller`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.post<any>(deactivateResellerUrl, data, {headers} );
  }

  getACategory(token: any, category: any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var categoryUrl = `${this.apiUrl}productCategory/${category}`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.get<any>(categoryUrl, {headers} );
  }

  getOffersByRequest(token: any, request: any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var offersByRequestUrl = `${this.apiUrl}getoffersbyrequestid/${request}`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.get<any>(offersByRequestUrl, {headers} );
  }

  getAItem(token: any, item: any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var itemUrl = `${this.apiUrl}productitem/${item}`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.get<any>(itemUrl, {headers} );
  }

  updateResellerProduct(token:any, data: any): Observable<any>{
    var auth_header = "Bearer " + token;
    var updateResellerProductUrl = this.apiUrl + "update/resellerProduct";
    var headers = default_headers.append("Authorization", auth_header);
    return this.http.patch<any>(updateResellerProductUrl, data, {headers} );
  }

  approveProduct(token:any, data:any): Observable<any>{
    var auth_header = "Bearer " + token;
    var approveProductUrl = this.apiUrl + "approve/product";
    var headers = default_headers.append("Authorization", auth_header);
    return this.http.post<any>(approveProductUrl, data, {headers} );
  }

  approveSeller(token:any, data:any): Observable<any>{
    var auth_header = "Bearer " + token;
    var approveSellerUrl = this.apiUrl + "approve/seller";
    var headers = default_headers.append("Authorization", auth_header);
    return this.http.post<any>(approveSellerUrl, data, {headers} );
  }

  approveBuyer(token:any, data:any): Observable<any>{
    var auth_header = "Bearer " + token;
    var approveBuyerUrl = this.apiUrl + "approve/buyer";
    var headers = default_headers.append("Authorization", auth_header);
    return this.http.post<any>(approveBuyerUrl, data, {headers} );
  }

  stopProduct(token:any, data:any): Observable<any>{
    var auth_header = "Bearer " + token;
    var stopProductUrl = this.apiUrl + "stop/product";
    var headers = default_headers.append("Authorization", auth_header);
    return this.http.post<any>(stopProductUrl, data, {headers} );
  }

  deleteProduct(token:any, data:any): Observable<any>{
    var auth_header = "Bearer " + token;
    var deleteProductUrl = this.apiUrl + "delete/product";
    var headers = default_headers.append("Authorization", auth_header);
    return this.http.post<any>(deleteProductUrl, data, {headers} );
  }

  stopSeller(token:any, data:any): Observable<any>{
    var auth_header = "Bearer " + token;
    var stopSellerUrl = this.apiUrl + "stop/seller";
    var headers = default_headers.append("Authorization", auth_header);
    return this.http.post<any>(stopSellerUrl, data, {headers} );
  }

  stopBuyer(token:any, data:any): Observable<any>{
    var auth_header = "Bearer " + token;
    var stopBuyerUrl = this.apiUrl + "stop/buyer";
    var headers = default_headers.append("Authorization", auth_header);
    return this.http.post<any>(stopBuyerUrl, data, {headers} );
  }

  getStatistics(token: any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var statsUrl = `${this.apiUrl}user/stats`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.get<any>(statsUrl, {headers} );
  }

  getPieChartData(token: any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var pieChartUrl = `${this.apiUrl}user/piechart`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.get<any>(pieChartUrl, {headers} );
  }

  getTopSellers(token: any): Observable<any>{
    var auth_header = `Bearer ${token}`;
    var topSellersUrl = `${this.apiUrl}topsellers`;
    var headers = default_headers.set("Authorization", auth_header);
    return this.http.get<any>(topSellersUrl, {headers} );
  }
  // getReseller(token:any, reseller_id:any): Observable<any>{
  //   var auth_header = `Bearer ${token}`;
  //   var getOrderUrl = `${this.apiUrl}orders`;
  //   var headers = default_headers.set("Authorization", auth_header);
  //   return this.http.get<any>(getOrderUrl, {headers} );
  // }
}
