import {
  CdkVirtualScrollViewport,
  ScrollDispatcher,
} from '@angular/cdk/scrolling';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { userType } from '../../enum';
import { UtilityService } from '../../services/utility.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, filter } from 'rxjs/operators';
import { SelectProductToCompareComponent } from '../select-product-to-compare/select-product-to-compare.component';
import { ScrollDownService } from '../../services/scroll-down.service';

@Component({
  selector: 'app-basic-view',
  templateUrl: './basic-view.component.html',
  styleUrls: ['./basic-view.component.scss']
})
export class BasicViewComponent implements OnInit, AfterViewInit {
  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;
  frontImageUrl: any;
  // public selectedCategory: string = '';
  public selectedCategory: FormControl = new FormControl();
  public searchProduct: FormControl = new FormControl();
  categories: any;
  user: any;
  isBuyer = false;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private utility: UtilityService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public scrollService: ScrollDownService,
    private scrollDispatcher: ScrollDispatcher
  ) { }

  ngOnInit(): void {
    this.apiService.getAllCategories().subscribe((categories) => {
      this.categories = categories.categories;
    });
    this.user = localStorage.getItem('user');
    this.user = JSON.parse(this.user);
    if (this?.user?.user_type === userType.BUYER){
      this.isBuyer = true;
    } else if(this?.user?.user_type === userType.SELLER){
      this.isBuyer = false;
    }
  }

  ngAfterViewInit(): void {
    this.virtualScroll.checkViewportSize();
    this.scrollDispatcher
      .scrolled()
      .pipe(
        filter(
          (event) => this.virtualScroll.measureScrollOffset('bottom') === 0
        )
      )
      .subscribe((event) => {
        console.log("scrolled subscribed event");
        console.log(event)
      });
  }

  login(type: string){
    this.router.navigateByUrl(`login?type=${type}`);
  }

  openFullSpec(product: any){
    console.log("priduct", product);
    this.router.navigate([`/fullspec`, product._id]);
  }

  addCategory(category: any){
    console.log("category ", category);
    this.selectedCategory.setValue(category._id);
    this.router.navigateByUrl(`?category=${category._id}`);
  }

  searchInProduct(){
    console.log(this.searchProduct.value.split(" "));
    this.router.navigateByUrl(`?search=${this.searchProduct.value}`);
  }

  redirectToBuyerHomepage(){
    this.router.navigateByUrl(`homepage/stats`);
  }

  openHomepage(){
    if (window.location.pathname == "/" || window.location.pathname == "/" || window.location.origin == window.location.href){
      window.location.reload();
    } else{
      this.router.navigateByUrl('/');
    }
  }

  redirectToPage(redirectElement: string){
    this.router.navigateByUrl(`/${redirectElement}`);
  }

  compareProducts(){
    let products = localStorage.getItem('products');
    if (products){
      let productsss = JSON.parse(products);
      const config = this.utility.getDialogConfiguration({});
      config.data.products = productsss;
      // config.data.secondProducts = products;
      const dialogRef2 = this.dialog.open(SelectProductToCompareComponent, config);
      dialogRef2.afterClosed().subscribe(page => {
        // if (!!page && page.status){
        //   if(this.user){
        //     if (this.user.user_type === userType.SELLER || this.user.user_type === userType.ADMIN){
        //       this.router.navigateByUrl(`/login?type=buyer`);
        //     } else{
        //       this.utility.requestOffer(product, this.user);
        //     }
        //   } else{
        //     this.router.navigateByUrl(`/login?type=buyer`);
        //   }
        // }
      });
    } else{
      this.snackBar.open(`There is no products to compare.` , 'OK', {
        duration: 7500,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      })
    }
  }

  resetCompare(){
    localStorage.removeItem('products');
    this.snackBar.open(`Reset Comparision Successfully` , 'OK', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    })
  }

  scrollDown(event: any){
    if(event.target.scrollHeight - 950< event.target.scrollTop){
      this.scrollService.scroll$.next(true);
      // this.scrollService.scroll.subscribe((scrollEvent)=>{
      //   if (!scrollEvent){
      //     this.scrollService.scroll$.next(true);
      //   }
      // })
    }
  }
}
