import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { COMPARE_KEY } from '../../constants';
import { BACKEND_IP } from '../../constants';
import * as CryptoJS from 'crypto-js';
import { UtilityService } from '../../services/utility.service';
import { userType } from '../../enum';

@Component({
  selector: 'app-compare-products',
  templateUrl: './compare-products.component.html',
  styleUrls: ['./compare-products.component.scss']
})
export class CompareProductsComponent implements OnInit {
  user: any;
  products: any;
  backendIp: string;
  displayImage: string;
  compareData:any = [];
  basicDetails: any = [];
  powerSupply: any = [];
  outputSignals: any = [];
  communicationSW: any = [];
  transmitter: any = [];
  enclosure: any = [];
  installation: any = [];
  cataloges: any = [];
  certies: any = [];
  profileDetails: any = [];
  requestOffers: any = [];
  displayedColumns: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public utility: UtilityService
  ) {
    this.backendIp = BACKEND_IP;
  }

  ngOnInit(): void {
    this.user = localStorage.getItem('user');
    this.user = JSON.parse(this.user);
    if(this.route.snapshot.params.id){
      const bytes = CryptoJS.AES.decrypt(this.route.snapshot.params.id, COMPARE_KEY);
      if (bytes.toString()) {
        this.products =  JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
    }
    if (!!this.products?.p3){
      this.products = [this.products.p1, this.products.p2, this.products.p3];
      this.displayedColumns = ['def', 'Details of First Product', 'Details of Second Product', 'Details of Third Product'];
    } else{
      this.products = [this.products.p1, this.products.p2];
      this.displayedColumns = ['def', 'Details of First Product', 'Details of Second Product'];
    }
    
    this.prepareCompareTable()
  }

  requestOffer(product: any){
    if(this.user){
      if (this.user.user_type === userType.SELLER || this.user.user_type === userType.ADMIN){
        this.router.navigateByUrl(`/login?type=buyer`);
      } else{
        this.utility.requestOffer(product, this.user);
      }
    } else{
      this.router.navigateByUrl(`/login?type=buyer`);
    }
  }


  prepareCompareTable(){
    this.profileDetails = [
      {
        "p0": "",
        "p1": {id: this.products[0]?._id},
        "p2": {id: this.products[1]?._id},
        "p3": {id: this.products[2]?._id}
      }
    ]

    this.requestOffers = [
      {
        "p0": "",
        "p1": {id: this.products[0]?._id},
        "p2": {id: this.products[1]?._id},
        "p3": {id: this.products[2]?._id}
      }
    ]

    this.basicDetails = [
      {
        "p0": "Category",
        "p1": this.products[0]?.category?.category,
        "p2": this.products[1]?.category?.category,
        "p3": this.products[2]?.category?.category
      },
      {
        "p0": "Item",
        "p1": this.products[0]?.item?.item,
        "p2": this.products[1]?.item?.item,
        "p3": this.products[2]?.item?.item
      }, 
      {
        "p0": "Product Type",
        "p1": this.products[0]?.type,
        "p2": this.products[1]?.type,
        "p3": this.products[2]?.type
      },
      {
        "p0": "Make",
        "p1": this.products[0]?.make,
        "p2": this.products[1]?.make,
        "p3": this.products[2]?.make
      },
      {
        "p0": "Model",
        "p1": this.products[0]?.model,
        "p2": this.products[1]?.model,
        "p3": this.products[2]?.model
      },
      {
        "p0": "Accuracy",
        "p1": this.products[0]?.basic_details?.accuracy,
        "p2": this.products[1]?.basic_details?.accuracy,
        "p3": this.products[2]?.basic_details?.accuracy
      },
      {
        "p0": "Material",
        "p1": this.products[0]?.basic_details?.material,
        "p2": this.products[1]?.basic_details?.material,
        "p3": this.products[2]?.basic_details?.material
      },
      {
        "p0": "Min Range",
        "p1": `${this.products[0]?.basic_details?.min_range} ${this.products[0]?.basic_details?.range_unit}`,
        "p2": `${this.products[1]?.basic_details?.min_range} ${this.products[1]?.basic_details?.range_unit}`,
        "p3": `${this.products[2]?.basic_details?.min_range} ${this.products[2]?.basic_details?.range_unit}`
      },
      {
        "p0": "Max Range",
        "p1": `${this.products[0]?.basic_details?.max_range} ${this.products[0]?.basic_details?.range_unit}`,
        "p2": `${this.products[1]?.basic_details?.max_range} ${this.products[1]?.basic_details?.range_unit}`,
        "p3": `${this.products[2]?.basic_details?.max_range} ${this.products[2]?.basic_details?.range_unit}`
      },
      {
        "p0": "Min Pressure",
        "p1": `${this.products[0]?.basic_details?.min_pressure} ${this.products[0]?.basic_details?.pressure_unit}`,
        "p2": `${this.products[1]?.basic_details?.min_pressure} ${this.products[1]?.basic_details?.pressure_unit}`,
        "p3": `${this.products[2]?.basic_details?.min_pressure} ${this.products[2]?.basic_details?.pressure_unit}`
      },
      {
        "p0": "Max Pressure",
        "p1": `${this.products[0]?.basic_details?.max_pressure} ${this.products[0]?.basic_details?.pressure_unit}`,
        "p2": `${this.products[1]?.basic_details?.max_pressure} ${this.products[1]?.basic_details?.pressure_unit}`,
        "p3": `${this.products[2]?.basic_details?.max_pressure} ${this.products[2]?.basic_details?.pressure_unit}`
      },
      {
        "p0": "Min Temprature",
        "p1": `${this.products[0]?.basic_details?.min_temprature} ${this.products[0]?.basic_details?.temprature_unit}`,
        "p2": `${this.products[1]?.basic_details?.min_temprature} ${this.products[1]?.basic_details?.temprature_unit}`,
        "p3": `${this.products[2]?.basic_details?.min_temprature} ${this.products[2]?.basic_details?.temprature_unit}`
      },
      {
        "p0": "Max Temprature",
        "p1": `${this.products[0]?.basic_details?.max_temprature} ${this.products[0]?.basic_details?.temprature_unit}`,
        "p2": `${this.products[1]?.basic_details?.max_temprature} ${this.products[1]?.basic_details?.temprature_unit}`,
        "p3": `${this.products[2]?.basic_details?.max_temprature} ${this.products[2]?.basic_details?.temprature_unit}`
      },
      {
        "p0": "Media Type",
        "p1": this.products[0]?.basic_details?.media_type,
        "p2": this.products[1]?.basic_details?.media_type,
        "p3": this.products[2]?.basic_details?.media_type
      },
      {
        "p0": "Resolution",
        "p1": this.products[0]?.basic_details?.resolution,
        "p2": this.products[1]?.basic_details?.resolution,
        "p3": this.products[2]?.basic_details?.resolution
      }
    ]

    this.powerSupply = [
      {
        "p0": "AC",
        "p1": !!this.products[0]?.power_supply?.ac ? `${this.products[0]?.power_supply?.ac} ${!!this.products[0]?.power_supply?.ac ? 'V' : ''}` : '' ,
        "p2": !!this.products[1]?.power_supply?.ac ? `${this.products[1]?.power_supply?.ac} ${!!this.products[1]?.power_supply?.ac ? 'V' : ''}` : '',
        "p3": !!this.products[2]?.power_supply?.ac ? `${this.products[2]?.power_supply?.ac} ${!!this.products[2]?.power_supply?.ac ? 'V' : ''}` : ''
      },
      {
        "p0": "DC",
        "p1": !!this.products[0]?.power_supply?.dc ? `${this.products[0]?.power_supply?.dc} ${!!this.products[0]?.power_supply?.dc ? 'V' : ''}` : '',
        "p2": !!this.products[1]?.power_supply?.dc ? `${this.products[1]?.power_supply?.dc} ${!!this.products[1]?.power_supply?.dc ? 'V' : ''}` : '',
        "p3": !!this.products[2]?.power_supply?.dc ? `${this.products[2]?.power_supply?.dc} ${!!this.products[2]?.power_supply?.dc ? 'V' : ''}` : ''
      }
    ]

    this.outputSignals = [
      {
        "p0": "MA",
        "p1": !!this.products[0]?.output_signals?.ma ? 'Available' : 'Not Available',
        "p2": !!this.products[1]?.output_signals?.ma ? 'Available' : 'Not Available',
        "p3": !!this.products[2]?.output_signals?.ma ? 'Available' : 'Not Available'
      },
      {
        "p0": "RS232",
        "p1": !!this.products[0]?.output_signals?.rs232 ? 'Available' : 'Not Available',
        "p2": !!this.products[1]?.output_signals?.rs232 ? 'Available' : 'Not Available',
        "p3": !!this.products[2]?.output_signals?.rs232 ? 'Available' : 'Not Available'
      },
      {
        "p0": "RS485",
        "p1": !!this.products[0]?.output_signals?.rs485 ? 'Available' : 'Not Available',
        "p2": !!this.products[1]?.output_signals?.rs485 ? 'Available' : 'Not Available',
        "p3": !!this.products[2]?.output_signals?.rs485 ? 'Available' : 'Not Available'
      }
    ]

    this.communicationSW = [
      {
        "p0": "HART",
        "p1": this.products[0]?.communication_software?.hart,
        "p2": this.products[1]?.communication_software?.hart,
        "p3": this.products[2]?.communication_software?.hart
      },
      {
        "p0": "RS232",
        "p1": this.products[0]?.communication_software?.rs232,
        "p2": this.products[1]?.communication_software?.rs232,
        "p3": this.products[2]?.communication_software?.rs232
      },
      {
        "p0": "RS485",
        "p1": this.products[0]?.communication_software?.rs485,
        "p2": this.products[1]?.communication_software?.rs485,
        "p3": this.products[2]?.communication_software?.rs485
      }
    ]

    this.transmitter = [
      {
        "p0": "Element Material",
        "p1": this.products[0]?.transmitter?.element_material,
        "p2": this.products[1]?.transmitter?.element_material,
        "p3": this.products[2]?.transmitter?.element_material
      },
      {
        "p0": "Housing",
        "p1": this.products[0]?.transmitter?.housing,
        "p2": this.products[1]?.transmitter?.housing,
        "p3": this.products[2]?.transmitter?.housing
      },
      {
        "p0": "Paint",
        "p1": this.products[0]?.transmitter?.paint,
        "p2": this.products[1]?.transmitter?.paint,
        "p3": this.products[2]?.transmitter?.paint
      },
      {
        "p0": "Range Limit",
        "p1": this.products[0]?.transmitter?.range_limit,
        "p2": this.products[1]?.transmitter?.range_limit,
        "p3": this.products[2]?.transmitter?.range_limit
      }
    ]

    this.enclosure = [
      {
        "p0": "IP",
        "p1": this.products[0]?.enclosure?.ip_ratting,
        "p2": this.products[1]?.enclosure?.ip_ratting,
        "p3": this.products[2]?.enclosure?.ip_ratting
      },
      {
        "p0": "Housing Material",
        "p1": this.products[0]?.enclosure?.housing_material,
        "p2": this.products[1]?.enclosure?.housing_material,
        "p3": this.products[2]?.enclosure?.housing_material
      },
      {
        "p0": "Hazardous Zone",
        "p1": this.products[0]?.enclosure?.hazardous_zone_class,
        "p2": this.products[1]?.enclosure?.hazardous_zone_class,
        "p3": this.products[2]?.enclosure?.hazardous_zone_class
      },
      {
        "p0": "Dimension",
        "p1": this.products[0]?.enclosure?.dimension,
        "p2": this.products[1]?.enclosure?.dimension,
        "p3": this.products[2]?.enclosure?.dimension
      }
    ]

    this.installation = [
      {
        "p0": "Insertion Type",
        "p1": !!this.products[0]?.installation_details?.insertion_type ? 'Available' : 'Not Available',
        "p2": !!this.products[1]?.installation_details?.insertion_type ? 'Available' : 'Not Available',
        "p3": !!this.products[2]?.installation_details?.insertion_type ? 'Available' : 'Not Available'
      },
      {
        "p0": "Installation Accessories",
        "p1": !!this.products[0]?.installation_details?.installation_accessories ? 'Available' : 'Not Available',
        "p2": !!this.products[1]?.installation_details?.installation_accessories ? 'Available' : 'Not Available',
        "p3": !!this.products[2]?.installation_details?.installation_accessories ? 'Available' : 'Not Available'
      },
      {
        "p0": "Mountaing Bracket",
        "p1": !!this.products[0]?.installation_details?.mountaing_bracket ? 'Available' : 'Not Available',
        "p2": !!this.products[1]?.installation_details?.mountaing_bracket ? 'Available' : 'Not Available',
        "p3": !!this.products[2]?.installation_details?.mountaing_bracket ? 'Available' : 'Not Available'
      },
      {
        "p0": "Panel Mounted",
        "p1": !!this.products[0]?.installation_details?.panel_mounted ? 'Available' : 'Not Available',
        "p2": !!this.products[1]?.installation_details?.panel_mounted ? 'Available' : 'Not Available',
        "p3": !!this.products[2]?.installation_details?.panel_mounted ? 'Available' : 'Not Available'
      },
      {
        "p0": "Pipe Mounted",
        "p1": !!this.products[0]?.installation_details?.pipe_mounted ? 'Available' : 'Not Available',
        "p2": !!this.products[1]?.installation_details?.pipe_mounted ? 'Available' : 'Not Available',
        "p3": !!this.products[2]?.installation_details?.pipe_mounted ? 'Available' : 'Not Available'
      }
    ]

    this.cataloges = [
      {
        "p0": "Download Sensor Catalog",
        "p1": {id: this.products[0]?._id, endpoint: 'sensor', label: 'Sensor Catalog'},
        "p2": {id: this.products[1]?._id, endpoint: 'sensor', label: 'Sensor Catalog'},
        "p3": {id: this.products[2]?._id, endpoint: 'sensor', label: 'Sensor Catalog'}
      },
      {
        "p0": "Download Instrument Catalog",
        "p1": {id: this.products[0]?._id, endpoint: 'instrument', label: 'Instrument Catalog'},
        "p2": {id: this.products[1]?._id, endpoint: 'instrument', label: 'Instrument Catalog'},
        "p3": {id: this.products[2]?._id, endpoint: 'instrument', label: 'Instrument Catalog'}
      },
      {
        "p0": "Download Installation Manual",
        "p1": {id: this.products[0]?._id, endpoint: 'installation', label: 'Installation Manual'},
        "p2": {id: this.products[1]?._id, endpoint: 'installation', label: 'Installation Manual'},
        "p3": {id: this.products[2]?._id, endpoint: 'installation', label: 'Installation Manual'}
      },
      {
        "p0": "Download Certificate",
        "p1": {id: this.products[0]?._id, endpoint: 'certificates', label: 'Certificates'},
        "p2": {id: this.products[1]?._id, endpoint: 'certificates', label: 'Certificates'},
        "p3": {id: this.products[2]?._id, endpoint: 'certificates', label: 'Certificates'}
      }
    ]
  }

}
