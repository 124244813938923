<!-- <mat-form-field appearance="fill">
    <mat-label>Input</mat-label>
    <input matInput>
</mat-form-field>
<mat-form-field appearance="fill">
    <mat-label>Select</mat-label>
    <mat-select>
      <mat-option value="one">First option</mat-option>
      <mat-option value="two">Second option</mat-option>
    </mat-select>
</mat-form-field>
<mat-form-field appearance="fill">
    <mat-label>Textarea</mat-label>
    <textarea matInput></textarea>
</mat-form-field>
<mat-form-field daid="auth-vk-inptff" appearance="fill" floatLabel="always" class="mfield-vk">
    <mat-label>label</mat-label>
    <input daid="auth-vk-input" matInput type="password" [formControl]="vk" placeholder="add password">
    <mat-error *ngIf="vk.invalid">
      <div *ngIf="vk.errors?.required">required</div>
      <div *ngIf="vk.errors?.minlength">minlength</div>
    </mat-error>
    <mat-error *ngIf="vk.invalid && vk.errors?.backendException">
      error
    </mat-error>
</mat-form-field> -->

<!-- <div class="centered-card-wrapper">
    <mat-card class="centered-card">
        <form>
            <mat-card-title class="mat-card-title">
                <h2>Verify the code recieved in email</h2>
            </mat-card-title>
            <div class="fcol m-fcol m-fw">
                <div class="fcol relative fx m-fw">
                    <mat-form-field appearance="fill" floatLabel="always" class="mfield-vk">
                        <mat-label>Enter 6 digit Auth Code*</mat-label>
                        <input matInput type="number" [formControl]="otp" placeholder="Auth Code">
                        <mat-error *ngIf="otp.invalid">
                            <div *ngIf="otp.errors?.minlength">Length of OTP should be 6.</div>
                        </mat-error>
                        <mat-error *ngIf="otp.invalid && otp.errors?.backendException">
                            invalid
                        </mat-error>
                    </mat-form-field>
                </div>
                <button mat-raised-button class="btn sbmt" (click)="verifyEmail()" [disabled]="grp.invalid">
                    <i class="ms-Icon ms-Icon--UnlockSolid" *ngIf="!waiting && !loading$" aria-hidden="true"></i>
                    <span *ngIf="!waiting && !loading$">Verify</span>
                    <span *ngIf="waiting || loading$">
                        <mat-progress-spinner mode="indeterminate" diameter="16" color="{{ waiting ? 'background' : '$primary' }}">
                        </mat-progress-spinner>
                    </span>
                    <span *ngIf="waiting">logging in...</span>
                    <span *ngIf="loading$">Loading</span>
                </button>
                <button mat-raised-button class="btn sbmt" (click)="verifyEmail()" [disabled]="grp.invalid">
                    <i class="ms-Icon ms-Icon--UnlockSolid" *ngIf="!waiting && !loading$" aria-hidden="true"></i>
                    <span *ngIf="!waiting && !loading$">Resend code</span>
                    <span *ngIf="waiting || loading$">
                        <mat-progress-spinner mode="indeterminate" diameter="16" color="{{ waiting ? 'background' : '$primary' }}">
                        </mat-progress-spinner>
                    </span>
                    <span *ngIf="waiting">logging in...</span>
                    <span *ngIf="loading$">Loading</span>
                </button>
                <button mat-raised-button class="btn sbmt" (click)="verifyEmail()" [disabled]="grp.invalid">
                    <i class="ms-Icon ms-Icon--UnlockSolid" *ngIf="!waiting && !loading$" aria-hidden="true"></i>
                    <span *ngIf="!waiting && !loading$">Cancel</span>
                    <span *ngIf="waiting || loading$">
                        <mat-progress-spinner mode="indeterminate" diameter="16" color="{{ waiting ? 'background' : '$primary' }}">
                        </mat-progress-spinner>
                    </span>
                    <span *ngIf="waiting">logging in...</span>
                    <span *ngIf="loading$">Loading</span>
                </button>
            </div>
        </form>
    </mat-card>
</div> -->
<h2 mat-dialog-title class="ellipsis">
    Verify the OTP recieved in email
</h2>
<!-- <button (click)="closeDialog()">Close</button> -->
<form mat-dialog-content>
    <mat-form-field appearance="outline" floatLabel="always" class="mfield-vk">
        <mat-label>Enter 6 digit Auth Code*</mat-label>
        <input matInput type="number" [formControl]="otp" placeholder="Auth Code" required>
        <mat-error *ngIf="otp.invalid">
            <div *ngIf="otp.errors?.minlength">Length of OTP should be 6.</div>
        </mat-error>
        <mat-error *ngIf="otp.invalid && otp.errors?.backendException">
            invalid
        </mat-error>
    </mat-form-field>

    <div class="fcol m-fcol m-fw">

        <button mat-raised-button class="btn sbmt" (click)="verifyEmail()" [disabled]="grp.invalid">
            <i class="ms-Icon ms-Icon--UnlockSolid" *ngIf="!waiting && !loading$" aria-hidden="true"></i>
            <span *ngIf="!waiting && !loading$">Verify</span>
            <span *ngIf="waiting || loading$">
                <mat-progress-spinner mode="indeterminate" diameter="16" color="{{ waiting ? 'background' : '$primary' }}">
                </mat-progress-spinner>
            </span>
            <span *ngIf="waiting">logging in...</span>
            <span *ngIf="loading$">Loading</span>
        </button>
        <button mat-raised-button class="btn sbmt" (click)="resendOtp()">
            <i class="ms-Icon ms-Icon--UnlockSolid" *ngIf="!waiting && !loading$" aria-hidden="true"></i>
            <span *ngIf="!waiting && !loading$">Resend code</span>
            <span *ngIf="waiting || loading$">
                <mat-progress-spinner mode="indeterminate" diameter="16" color="{{ waiting ? 'background' : '$primary' }}">
                </mat-progress-spinner>
            </span>
            <span *ngIf="waiting">logging in...</span>
            <span *ngIf="loading$">Loading</span>
        </button>
        <button mat-raised-button class="btn sbmt" (click)="closeDialog(false)">
            <i class="ms-Icon ms-Icon--UnlockSolid" *ngIf="!waiting && !loading$" aria-hidden="true"></i>
            <span *ngIf="!waiting && !loading$">Cancel</span>
            <span *ngIf="waiting || loading$">
                <mat-progress-spinner mode="indeterminate" diameter="16" color="{{ waiting ? 'background' : '$primary' }}">
                </mat-progress-spinner>
            </span>
            <span *ngIf="waiting">logging in...</span>
            <span *ngIf="loading$">Loading</span>
        </button>
    </div>
</form>