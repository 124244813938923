<!-- <mat-form-field appearance="fill">
    <mat-label>Input</mat-label>
    <input matInput>
</mat-form-field>
<mat-form-field appearance="fill">
    <mat-label>Select</mat-label>
    <mat-select>
      <mat-option value="one">First option</mat-option>
      <mat-option value="two">Second option</mat-option>
    </mat-select>
</mat-form-field>
<mat-form-field appearance="fill">
    <mat-label>Textarea</mat-label>
    <textarea matInput></textarea>
</mat-form-field>
<mat-form-field daid="auth-vk-inptff" appearance="fill" floatLabel="always" class="mfield-vk">
    <mat-label>label</mat-label>
    <input daid="auth-vk-input" matInput type="password" [formControl]="vk" placeholder="add password">
    <mat-error *ngIf="vk.invalid">
      <div *ngIf="vk.errors?.required">required</div>
      <div *ngIf="vk.errors?.minlength">minlength</div>
    </mat-error>
    <mat-error *ngIf="vk.invalid && vk.errors?.backendException">
      error
    </mat-error>
</mat-form-field> -->

<!-- Do not modify in below code -->

<!--------------------------------------------------------------
#Tested Code -  Do Not modify below 
-------------------------------------------------------------->

<div class="centered-card-wrapper">
    <mat-card class="centered-card">
        <form>
            <mat-card-title class="mat-card-title">
                <!-- <h2>InstrumentFlow - Login</h2> -->
                <h1 class="homepage-app-name cpointer"><img src="../../../../assets/applogo/Brandlogo.svg" (click)="openHomepage()" alt=""></h1>
            </mat-card-title>
            <div class="fcol m-fcol m-fw">
                <div class="fcol relative fx m-fw">
                    <mat-form-field appearance="outline" floatLabel="always" class="mfield-vk">
                        <mat-label>Username</mat-label>
                        <input matInput type="text" [formControl]="username" placeholder="Email Address">
                        <mat-error *ngIf="username.invalid">
                            <div *ngIf="username.errors?.required">required</div>
                            <div *ngIf="username.errors?.pattern">Invalid Format</div>
                        </mat-error>
                        <mat-error *ngIf="username.invalid && username.errors?.backendException">
                            invalid
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Password</mat-label>
                        <input matInput type="password" [formControl]="password" placeholder="Password">
                        <mat-error *ngIf="password.invalid">
                            <div *ngIf="password.errors?.required">required</div>
                            <div *ngIf="password.errors?.minlength">Length of password should be 6.</div>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>User type</mat-label>
                        <mat-select [formControl]="user_type">
                          <mat-option value="seller">Seller</mat-option>
                          <mat-option value="buyer">Buyer</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button mat-button type="button" class="mat-focus-indicator lnk-btn fcol fas-bottom mat-button mat-button-base" (click)="forgotPassword()">Forgot Password ?</button>
                </div>
                <button mat-raised-button class="btn sbmt fas-bottom" color="primary" (click)="login()" [disabled]="grp.invalid">
                    <i class="ms-Icon ms-Icon--UnlockSolid" *ngIf="!loading$" aria-hidden="true"></i>
                    <span *ngIf="!loading$">Login</span>
                    <div class="frow fcenter" *ngIf="loading$">
                        <span class="pad-right-5">Logging in...</span>
                        <span>
                            <mat-progress-spinner mode="indeterminate" diameter="16">
                            </mat-progress-spinner>
                        </span>
                    </div>
                </button>
                <div class="frow fjc-end marg-top-5">
                    <span class="disable-op">Don't have an account? </span>
                    <button class="mat-focus-indicator lnk-btn mat-button mat-button-base" (click)="signin()">SignUp</button>
                </div>
                <div class="frow fjc-end marg-top-5">
                    <button class="mat-focus-indicator lnk-btn mat-button mat-button-base" (click)="verifyUser()">Verify User</button>
                </div>
            </div>
        </form>
    </mat-card>
</div>

