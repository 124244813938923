import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/core/services/api.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  public currentPassword: FormControl = new FormControl('', [Validators.required, Validators.minLength(8)]);
  public newPassword: FormControl = new FormControl('', [Validators.required, Validators.minLength(8)]);
  public confirmPassword: FormControl = new FormControl('', [Validators.required, Validators.minLength(8)]);
  public grp: FormGroup = new FormGroup({ currentPassword: this.currentPassword, newPassword: this.newPassword, confirmPassword: this.confirmPassword });

  constructor(
    private snackbar: MatSnackBar,
    public apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public dlgData: any,
    private dialogRef: MatDialogRef<ChangePasswordComponent>,
    private router: Router
  ) { }

  ngOnInit(): void {
    console.log(this.dlgData);
  }

  resetForm(){

  }

  changePassword(){
    if(this.newPassword.value === this.confirmPassword.value){
      this.apiService.changePassword(this.dlgData.user.token, {
        email: this.dlgData.user.email,
        currentPassword: this.currentPassword.value,
        newPassword: this.newPassword.value
      }).subscribe((resp)=>{
        console.log(resp);
        this.dialogRef.close();
        this.snackbar.open('Succesfully changed the password.', 'OK', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        localStorage.removeItem('user');
        this.router.navigateByUrl("/login");
      }, (err) =>{
        console.log(err.error.message);
        this.snackbar.open(!!err.error.message? err.error.message: 'Unable to change password!', 'OK', {
          duration: 7500,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      })

    } else{
      this.snackbar.open('New Password and Confirm Password must be same.', 'OK', {
        duration: 7500,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
    }
  }
}
