import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/core/services/api.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public newPassword: FormControl = new FormControl('', [Validators.required, Validators.minLength(8)]);
  public confirmPassword: FormControl = new FormControl('', [Validators.required, Validators.minLength(8)]);
  public email: FormControl = new FormControl('',[
    Validators.required,
    Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$')
  ]);
  public grp: FormGroup = new FormGroup({ username: this.email, newPassword: this.newPassword, confirmPassword: this.confirmPassword  });
  public waiting: boolean | undefined;
  public loading$: boolean | undefined;
  public user_type: FormControl = new FormControl();

  constructor(
    public api: ApiService,
    private snackbar: MatSnackBar,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  getPassword(){
    this.api.forgotPassword({
      email: this.email.value,
      type: this.user_type.value
    }).subscribe((res)=>{
      if (res.success){
        this.snackbar.open('Please check the mail to reset password.', 'OK', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        this.router.navigateByUrl("/login");
      }
    }, (err) =>{
      this.snackbar.open(!!err.error.error ? err.error.error : 'Error to get password for this email. Please try again after some time.', 'OK', {
        duration: 7500,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
    })
  }

}
