import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './authentication/components/login/login.component';
import { SigninComponent } from './authentication/components/signin/signin.component';
import { ForgotPasswordComponent } from './authentication/components/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './authentication/components/verify-email/verify-email.component';
import { TempComponent } from './core/components/temp/temp.component';
import { BasicViewComponent } from './core/components/basic-view/basic-view.component';
import { BasicProductComponent } from './core/components/basic-product/basic-product.component';
import { FullSpecComponent } from './core/components/full-spec/full-spec.component';
import { AboutUsComponent } from './core/components/about-us/about-us.component';
import { ContactUsComponent } from './core/components/contact-us/contact-us.component';
import { CarrerComponent } from './core/components/carrer/carrer.component';
import { PrivacyComponent } from './core/components/privacy/privacy.component';
import { TermsComponent } from './core/components/terms/terms.component';
import { CompareProductsComponent } from './core/components/compare-products/compare-products.component';
import { BlogComponent } from './core/components/blog/blog.component';
import { BlogDetailsComponent } from './core/components/blog/blog-details/blog-details.component';
const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    component: BasicViewComponent,
    children:[
      {
        path: '',
        component: BasicProductComponent
      },
      {
        path: 'fullspec/:id',
        component: FullSpecComponent
      },
      {
        path: 'compareproducts/:id',
        component: CompareProductsComponent
      },
      {
        path: 'about',
        component: AboutUsComponent
      },
      {
        path: 'contact',
        component: ContactUsComponent
      },
      {
        path: 'career',
        component: CarrerComponent
      },
      {
        path: 'privacy',
        component: PrivacyComponent
      },
      {
        path: 'terms',
        component: TermsComponent
      },
      {
        path:'blog',
        component: BlogComponent
      },
      {
        path:'blog/:id',
        component: BlogDetailsComponent
      }
    ]
  },
  {
    path: '',
    loadChildren: () => import('./features/dashboard/dashboard.module').then(mod => mod.DashboardModule)
  },
  {
    path: 'signin',
    component: SigninComponent
  }, 
  {
    path: 'forgotpassword',
    component: ForgotPasswordComponent
  },
  {
    path: 'verifyEmail',
    component: VerifyEmailComponent
  },
  {
    path: 'temp',
    component: TempComponent
  },
  // {
  //   path: 'profile',
  //   loadChildren: () => import('./features/profile/profile.module').then(mod => mod.ProfileModule)
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

