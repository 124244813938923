import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/core/services/api.service';
import * as CryptoJS from 'crypto-js';
import { COMPARE_KEY, BACKEND_IP } from '../../constants';

@Component({
  selector: 'app-select-product-to-compare',
  templateUrl: './select-product-to-compare.component.html',
  styleUrls: ['./select-product-to-compare.component.scss']
})
export class SelectProductToCompareComponent implements OnInit {
  // productForm: FormGroup;
  // firstProduct: any;
  allProducts: any;
  // public selectedSecondProduct: string = '';
  // public selectedThirdProduct: string = '';
  // secondProducts: any;
  // thirdProducts: any;
  user: any;
  token: any;
  backendIp: string;
  // thirdDisabled: boolean = true;

  constructor(
    private _formBuilder: FormBuilder,
    private apiService: ApiService,
    private snackBar: MatSnackBar,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) private dlgData: any,
    private dialogRef: MatDialogRef<SelectProductToCompareComponent>
  ) {
    this.allProducts = this.dlgData.products;
    // this.firstProduct = this.allProducts[0];
    // this.secondProducts = this.dlgData.secondProducts;
    this.backendIp = BACKEND_IP;
    // this.productForm = this._formBuilder.group({
      
    // });
    
  }

  ngOnInit(): void {
    this.user = localStorage.getItem('user');
    this.user = JSON.parse(this.user);
    if (!this.user || !this.user.token){
      localStorage.removeItem('user');
    } else{
      this.token = this.user.token;
    }
  }

  closeDialog(){
    this.dialogRef.close();
  }

  compareProduct(){
    let params = {}

    // if (this.selectedThirdProduct){
    //   params = {
    //     p1: this.firstProduct,
    //     p2: this.selectedSecondProduct,
    //     p3: this.selectedThirdProduct
    //   }
    // } else {
    //   params = {
    //     p1: this.firstProduct,
    //     p2: this.selectedSecondProduct
    //   }
    // }
    if (this.allProducts.length === 3){
      params = {
        p1: this.allProducts[0],
        p2: this.allProducts[1],
        p3: this.allProducts[2]
      }
    } else {
      params = {
        p1: this.allProducts[0],
        p2: this.allProducts[1]
      }
    }

    this.router.navigate([`/compareproducts`, CryptoJS.AES.encrypt(JSON.stringify(params), COMPARE_KEY).toString()]);
    this.closeDialog();

  }

  // selectSecondProduct(value: any){
  //   this.apiService.compareProducts({
  //     category: this.firstProduct.category._id,
  //     products: [this.firstProduct._id, value]
  //   }).subscribe((products) => {
  //     console.log("third products")
  //     console.log(products);
  //     if (products.length === 0){
  //       this.snackBar.open("Third product is not available with this category." , 'OK', {
  //         duration: 7500,
  //         horizontalPosition: 'center',
  //         verticalPosition: 'top'
  //       })
  //     } else{
  //       this.thirdDisabled = false;
  //       this.thirdProducts = products;
  //     }
  //   }, (err)=>{
  //     this.snackBar.open(err.error.error ? err.error.error : "Error occurred while fetching third product." , 'OK', {
  //       duration: 7500,
  //       horizontalPosition: 'center',
  //       verticalPosition: 'top'
  //     })
  //   });
  // }

  removeProduct(index:any){
    let products = localStorage.getItem('products');
    if (products){
      let productsass = JSON.parse(products);
      productsass.splice(index,1);
      localStorage.setItem('products', JSON.stringify(productsass));
      this.allProducts = productsass;
    }
  }

  resetCompare(){
    localStorage.removeItem('products');
    this.allProducts = [];
  }

}
