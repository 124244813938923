import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  session$: BehaviorSubject<any> = new BehaviorSubject<any>(true);

  constructor() { }

  get session(): Observable<any> {
    return this.session$.asObservable();
  }
}
