<!-- <div class="maincontainer">
    <div class="card-holder">
        <mat-card class="blog-card" *ngFor="let blog of blogs">
            <img [src]="getImageSrc(blog._id)" alt="">
            <mat-card-title>
                {{blog.title}}
            </mat-card-title>
            <p>
                {{blog.blog}}
            </p>

        <button mat-stroked-button routerLink="/blog/{{blog._id}}" class="viewfullblogbutton">View Full Blog</button>
        </mat-card>
      </div>
    </div> -->

<h1 class="main-heading"><b> Blog </b></h1>
<div class="basic-products">
  <div id="basic-products">
    <div class="blog-spinner" *ngIf="isBlogLoading">
      <mat-progress-spinner
        color="primary"
        mode="indeterminate"
        [diameter]="30"
      >
      </mat-progress-spinner>
      <span class="loader-tooltip">Loading Blog....</span>
    </div>

    <div class="each-product" *ngFor="let blog of blogs" loading="lazy">
      <div class="product-img">
        <img [src]="getImageSrc(blog._id)" alt="Image not Available" />
      </div>
      <div class="pad-5">
        <b>{{ blog.title }}</b>
      </div>
      <div class="pad-5"></div>
      <div class="pad-5 ellipsis-3">
        {{ blog.blog }}
      </div>
      <div class="pad-1">
        
        <span class="link cpointer" [routerLink]="['/blog/', blog._id]"
          >View Full Blog</span>
        <div class="admin-btn-container">
          <button mat-icon-button class="admin-btn" *ngIf="userType == 'admin'">
            <mat-icon> edit </mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 
<section class="sec">
  <div class="container ">
    <div class="wrapper-grid">
      <mat-card class="card" *ngFor="let blog of blogs">
        <div class="div">
          <img
            [src]="getImageSrc(blog._id)"
          />
          <div class="p-6">
            <h1 class="title-font text-lg font-medium text-black mb-3">
              {{ blog.title }}
            </h1>
            <p class="leading-relaxed mb-3">{{ blog.blog }}</p>
            <div class="flex items-center flex-wrap">
              <a
                class="text-indigo-400 cursor-pointer inline-flex items-center md:mb-2 lg:mb-0"
                [routerLink]="['/blog/', blog._id]"
                >Full Blog
                
              </a>
              <span
                class="text-gray-400 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm pr-3 py-1 border-r-2 border-gray-200"
                *ngIf="this.userType == 'admin'"
              >
                <button mat-icon-button [routerLink]="['/homepage/updateBlog/', blog._id]">
                  <mat-icon>edit</mat-icon>
                </button>
              </span>
               <span
                class="text-gray-400  inline-flex items-center lg:ml-0 md:ml-0 leading-none text-sm  py-1"
                *ngIf="this.userType == 'admin'"
              >
                <button mat-icon-button [routerLink]="['/homepage/updateBlogImg/', blog._id]">
                  <mat-icon>format_paint</mat-icon>
                </button>
              </span>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</section> -->
