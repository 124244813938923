import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/core/services/api.service';
import { SessionService } from 'src/app/core/services/session.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilityService } from 'src/app/core/services/utility.service';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { VerifyUserComponent } from '../verify-user/verify-user.component';
import { userType } from 'src/app/core/enum';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  public password: FormControl = new FormControl('', [Validators.required, Validators.minLength(6)]);
  public username: FormControl = new FormControl('',[
    Validators.required,
    Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$')
  ]);
  public loading$: boolean | undefined;
  public user_type: FormControl = new FormControl();
  public grp: FormGroup = new FormGroup({ username: this.username, password: this.password, user_type:this.user_type });

  constructor(
    private apiService: ApiService,
    private session: SessionService,
    private router: Router,
    public utility: UtilityService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((qp)=>{
      if(qp && qp.email){
        this.username.setValue(qp.email);
      }
      if (qp && qp.type){
        this.user_type.setValue(qp.type);
        this.user_type.disable();
      }
    });
  }

  ngOnDestroy(): void {
    // alert("halo maro zalo")
    window.history.forward();
    // window.onbeforeunload = function() { return alert("Your work will be lost."); };
  }

  forgotPassword(){
    this.router.navigateByUrl('forgotpassword');
  }

  login(){
    this.loading$ = true;
    this.grp.disable();
    var user_details = {
      email: this.username.value,
      password: this.password.value,
      type: this.user_type.value
    }
    this.apiService.loginUser(user_details).subscribe((user) =>{
      if(user.user.reset){
        let config = this.utility.getDialogConfiguration({});
        config.data.user = user.user;
        const dialogRef = this.dialog.open(ChangePasswordComponent, config);
        dialogRef.afterClosed().subscribe(changedPassword => {
          // this.router.navigateByUrl("/homepage/login");
          this.grp.reset();
        });
        this.loading$ = false;
      } else{
        if (localStorage.getItem('user')){
          localStorage.removeItem('user');
        }
        localStorage.setItem('user', JSON.stringify(user.user));
        this.session.session$.next(true);
        if (user.user.user_type === userType.SELLER){
          this.router.navigateByUrl("/homepage/stats");
        } else if(user.user.user_type === userType.BUYER){
          this.router.navigateByUrl("/");
        } else if(user.user.user_type === userType.ADMIN){
          this.router.navigateByUrl("/homepage/stats");
        }
        this.loading$ = false;
      }
      // if (user.user.user_status.status_name == "pending"){
      //   this.router.navigateByUrl("/verification-pending");
      // } else if(user.user.user_status.status_name == "approved") {
      //   localStorage.setItem('user', JSON.stringify(user.user));
      //   this.waiting = false;
      //   this.router.navigateByUrl("/homepage");
      // }
    }, (login_error) => {
      console.log(login_error);
      this.snackbar.open(!!login_error.error.error ? login_error.error.error : 'Error to get password for this email. Please try again after some time.', 'OK', {
        duration: 7500,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      this.loading$ = false;
    })
    this.grp.enable();
  }

  signin(){
    this.router.navigateByUrl('signin');
  }

  verifyUser(){
    let config = this.utility.getDialogConfiguration({});
    const dialogRef = this.dialog.open(VerifyUserComponent, config);
    dialogRef.afterClosed().subscribe(user => {
      console.log("from utility", user);
    });
  }

  openHomepage(){
    this.router.navigateByUrl('/');
  }

}
