import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { UtilityService } from './utility.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ScrollDownService {
  scroll$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  constructor(
    private apiService: ApiService,
    private utility: UtilityService,
    private snackbar: MatSnackBar
  ) {
  }

  get scroll(): Observable<any> {
    return this.scroll$.asObservable();
  }
}
