<div class="about">
    <h2 class="mat-card-title">CAREER</h2>
    <div class="about-content">
        <p>We are hiring, Please send your CVs to Welcome@instrumentflow.com</p>        
    </div>
</div>

<div class="Joblists">

<mat-card class="Job-card">

    <mat-card-subtitle >Apply for below Positions / Opportunities are available for Short term, Long term, Contract & Task based. </mat-card-subtitle>

    <mat-card-title> Instrumentation Engineers - 03 Positions</mat-card-title>

    <mat-card-content>
      <p>1-5 years of experience, Fresher may apply.</p>
      <p>Position will be based in Surat Office </p>
      <p>Option available for Work from home </p>
    </mat-card-content>

    <mat-divider inset></mat-divider>
    <mat-card-actions>
      <button mat-button color="primary" matTooltip="welcome@instrumentflow.com">Apply</button>
      <button mat-button color="primary">Share</button>
    </mat-card-actions>
   
  </mat-card>

  <mat-card class="Job-card">

    <mat-card-subtitle>Apply for below Positions </mat-card-subtitle>

    <mat-card-title>Technical Sales Engineer - 02 Positions</mat-card-title>

    <mat-card-content>
      <p>1-5 years of experience, Fresher may apply.</p>
      <p>Position will be based in Surat Office </p>
      <p>Option available for Work from home </p>
    </mat-card-content>

    <mat-divider inset></mat-divider>
    <mat-card-actions>
      <button mat-button color="primary" matTooltip="welcome@instrumentflow.com">Apply</button>
      <button mat-button color="primary">Share</button>
    </mat-card-actions>
   
  </mat-card>

  <mat-card class="Job-card">

    <mat-card-subtitle>Apply for below Positions </mat-card-subtitle>

    <mat-card-title>Angular UI/UX developer - 02 Positions</mat-card-title>

    <mat-card-content>
      <p>1-5 years of experience, Fresher may apply.</p>
      <p>Position will be based in Surat Office </p>
      <p>Option available for Work from home </p>
    </mat-card-content>

    <mat-divider inset></mat-divider>
    <mat-card-actions>
      <button mat-button color="primary" matTooltip="welcome@instrumentflow.com">Apply</button>
      <button mat-button color="primary">Share</button>
    </mat-card-actions>
   
  </mat-card>

  <mat-card class="Job-card">

    <mat-card-subtitle>Apply for below Positions /  Opportunities are available for Short term, Long term, Contract & Task based. </mat-card-subtitle>

    <mat-card-title>Installation engineers / Technicians - Multiple Positions (Remote/ Pan India)</mat-card-title>

    <mat-card-content>
      <p>1-5 years of experience, Installation experience required.</p>
      <p>Position will be based in all major cities accross india  </p>
      <p>You will be assigned Projects/Sites around your home location </p>
    </mat-card-content>

    <mat-divider inset></mat-divider>
    <mat-card-actions>
      <button mat-button color="primary" matTooltip="welcome@instrumentflow.com">Apply</button>
      <button mat-button color="primary">Share</button>
    </mat-card-actions>
   
  </mat-card>

  <mat-card class="Job-card">

    <mat-card-subtitle>Apply for below Positions/  Opportunities are available for Short term, Long term, Contract & Task based. </mat-card-subtitle>

    <mat-card-title>Commissioning engineers/ Technicians - Multiple Positions (Remote / Pan India)</mat-card-title>

    <mat-card-content>
      <p>1-5 years of experience in commissioning instruments and Packages.</p>
      <p>Position will be based in all major cities accross india </p>
      <p>You will be assigned Projects/Sites around your home location </p>
    </mat-card-content>

    <mat-divider inset></mat-divider>
    <mat-card-actions>
      <button mat-button color="primary"  matTooltip="welcome@instrumentflow.com">Apply</button>
      <button mat-button color="primary">Share</button>
    </mat-card-actions>
   
  </mat-card>


</div>
