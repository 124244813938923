import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Input() loaderText = 'Please wait....';
  @Input() loaderMode = 'indeterminate';
  @Input() loaderDiameter = '24';
  @Input() loaderColor = 'accent';

  constructor() { }

  ngOnInit(): void {
  }

}
