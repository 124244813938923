import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/core/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { VerifyEmailComponent } from '../verify-email/verify-email.component';
import { MatDialog } from '@angular/material/dialog';
import { UtilityService } from 'src/app/core/services/utility.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  public password: FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(8)
  ]);
  public username: FormControl = new FormControl('',[
    Validators.required,
    Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$')
  ]);
  public user_type: FormControl = new FormControl();
  public grp: FormGroup = new FormGroup({ username: this.username, password: this.password, user_type: this.user_type });
  public waiting: boolean | undefined;
  public loading$: boolean | undefined;

  constructor(
    private apiService: ApiService,
    private router: Router,
    public dialog: MatDialog,
    public utility: UtilityService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((qp)=>{
      if(qp && qp.email){
        this.username.setValue(qp.email);
      }
      if (qp && qp.type){
        this.user_type.setValue(qp.type);
        this.user_type.disable();
      }
    });
  }

  signin(){
    this.waiting = true;
    this.grp.disable();
    var user_details = {
      email: this.username.value,
      password: this.password.value,
      type: this.user_type.value
    }
    this.apiService.signinUser(user_details).subscribe((user) =>{
      this.waiting = false;
      let config = this.utility.getDialogConfiguration({});
      config.data.email = user.user.email;
      config.data.user_type = user.user.user_type;
      const dialogRef = this.dialog.open(VerifyEmailComponent, config);
      dialogRef.afterClosed().subscribe(verifiedEmail => {
        if(verifiedEmail.status){
          this.login();
        } else{
          this.router.navigateByUrl('signin');
        }
      });
    }, (error) =>{
      this.waiting = false;
      this.grp.enable();
      this.snackBar.open(error.error.error, 'OK', {
        duration: 7500,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
    })
  }

  verifyOtp(){
    this.router.navigateByUrl('verifyEmail');
  }

  login(){
    this.router.navigateByUrl('login');
  }

  openHomepage(){
    this.router.navigateByUrl('/');
  }
}
