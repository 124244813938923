import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { HttpClientModule } from '@angular/common/http';
import { MatMenuModule } from '@angular/material/menu';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import {MatGridListModule} from '@angular/material/grid-list';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './authentication/components/login/login.component';
import { SigninComponent } from './authentication/components/signin/signin.component';
import { ForgotPasswordComponent } from './authentication/components/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './authentication/components/verify-email/verify-email.component';
import { TempComponent } from './core/components/temp/temp.component';
import { SessionExpiredComponent } from './authentication/components/session-expired/session-expired.component';
import { VerifyPasswordComponent } from './authentication/components/verify-password/verify-password.component';
import { ChangePasswordComponent } from './authentication/components/change-password/change-password.component';
import { LoaderComponent } from './core/components/loader/loader.component';
import { BasicViewComponent } from './core/components/basic-view/basic-view.component';
import { FullSpecComponent } from './core/components/full-spec/full-spec.component';
import { BasicProductComponent } from './core/components/basic-product/basic-product.component';
import { VerifyUserComponent } from './authentication/components/verify-user/verify-user.component';
import { AboutUsComponent } from './core/components/about-us/about-us.component';
import { ContactUsComponent } from './core/components/contact-us/contact-us.component';
import { CarrerComponent } from './core/components/carrer/carrer.component';
import { CallUsComponent } from './core/components/call-us/call-us.component';
import { ConfirmRequestComponent } from './core/components/confirm-request/confirm-request.component';
import { PrivacyComponent } from './core/components/privacy/privacy.component';
import { TermsComponent } from './core/components/terms/terms.component';
import { SelectProductToCompareComponent } from './core/components/select-product-to-compare/select-product-to-compare.component';
import { CompareProductsComponent } from './core/components/compare-products/compare-products.component';
import { MobileFiltersComponent } from './core/components/mobile-filters/mobile-filters.component';
import { NgChartsModule } from 'ng2-charts';
import { BlogComponent } from './core/components/blog/blog.component';
import { BlogDetailsComponent } from './core/components/blog/blog-details/blog-details.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SigninComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    TempComponent,
    SessionExpiredComponent,
    VerifyPasswordComponent,
    ChangePasswordComponent,
    LoaderComponent,
    BasicViewComponent,
    FullSpecComponent,
    BasicProductComponent,
    VerifyUserComponent,
    AboutUsComponent,
    ContactUsComponent,
    CarrerComponent,
    CallUsComponent,
    ConfirmRequestComponent,
    PrivacyComponent,
    TermsComponent,
    SelectProductToCompareComponent,
    CompareProductsComponent,
    MobileFiltersComponent,
    BlogComponent,
    BlogDetailsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatCardModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatIconModule,
    MatTableModule,
    MatButtonModule,
    MatDialogModule,
    MatSnackBarModule,
    MatStepperModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTabsModule,
    MatChipsModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatSortModule,
    MatPaginatorModule,
    Ng2TelInputModule,
    NgxIntlTelInputModule,
    MatListModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    MatButtonModule,
    MatMenuModule,
    NgChartsModule,
    MatGridListModule
  ],
  providers: [],
  exports: [
    LoaderComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
