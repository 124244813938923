<div class="about">
    <mat-card-title class="mat-card-title">
        <h2>ABOUT US</h2>
    </mat-card-title>
    <div class="about-content">
        <p>We Ensqual Technosolutions Private Limited are Department for Promotion of Industry and Internal Trade  (DPIIT) recognized startup under Government of India Startup program.</p>
        <p>We are developing futuristic systems and comprehensive platform for Engineering Procurement & Construction industry.  Full spectrum of Industrial Infrastructure (concept to completions) is our focus.</p>
        <p>As a stepping stone towards a bigger vision, Instrumentflow.com is a powerful, futuristic hybrid platform to bridge the gaps in procurement systems of Instrumentation business.</p>
        <p>Instrument flow is bringing Manufacturers, Distributors, Sellers, Buyers or even Freelancers and service providers on single platform to create dedicated, comprehensive instrumentation ecosystem.</p>
        <p>If you are into instrumentation business and India is your market, then Instrumentflow.com is where you need to be.</p>
        <p>For enquiries, Promotions, Investments or partnerships, please do write us at welcome@instrumentflow.com</p>
    </div>
</div>