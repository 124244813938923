import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/core/services/api.service';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {
  public otp: FormControl = new FormControl('', [Validators.minLength(6)]);
  public grp: FormGroup = new FormGroup({ otp: this.otp });
  public waiting: boolean | undefined;
  public loading$: boolean | undefined;
  public email: string = '';
  public user_type: string;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private dlgData: any,
    private dialogRef: MatDialogRef<VerifyEmailComponent>
  ) {}

  ngOnInit(): void {
    this.email = this.dlgData.email;
    this.user_type = this.dlgData.user_type;
  }

  verifyEmail(){
    console.log("email");
    console.log(this.email);
    console.log(this.user_type);
    this.apiService.verifyOtp(this.email, this.otp.value, this.user_type).subscribe((resp) => {
      if(resp.status){
        this.closeDialog(true);
      }
    }, (error) => {
      this.snackBar.open(error.error.error.message, 'OK', {
        duration: 7500,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
    });
  }

  resendOtp(){
    this.apiService.resendOtp(this.email, this.user_type).subscribe((resp) => {
      if(resp.status){
        this.snackBar.open('OTP has been sent again. Check your mail.', 'OK', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      }
    }, (error)=>{
      this.snackBar.open(error.error.error, 'OK', {
        duration: 7500,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
    })
  }

  closeDialog(status: boolean){
    this.dialogRef.close({status});
  }

}
