import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { UtilityService } from '../../services/utility.service';
import { BACKEND_IP } from '../../constants';
import { userType } from '../../enum';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmRequestComponent } from '../confirm-request/confirm-request.component';

@Component({
  selector: 'app-full-spec',
  templateUrl: './full-spec.component.html',
  styleUrls: ['./full-spec.component.scss']
})
export class FullSpecComponent implements OnInit, OnDestroy {
  product: any;
  product_id: any;
  backendIp: string;
  baseUrl: string;
  displayImage: string;
  user: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public api: ApiService,
    public utility: UtilityService,
    public dialog: MatDialog,
  ) {
    this.backendIp = BACKEND_IP;
  }

  ngOnInit(): void {
    window.scroll(10,10);
    this.product_id = this.route.snapshot.params.id;
    this.baseUrl = `${this.backendIp}product/${this.product_id}`
    this.api.getBasicProduct(this.route.snapshot.params.id).subscribe((product)=>{
      this.product = product;
      this.displayImage = `${this.baseUrl}/front`;
    });
    this.user = localStorage.getItem('user');
    this.user = JSON.parse(this.user);
  }

  ngOnDestroy(): void {
    window.scroll(10,10);
    // window.history.forward();
  }

  changeDisplayImage(imageSide: string){
    this.displayImage = `${this.baseUrl}/${imageSide}`;
  }

  requestOffer(){
    const config = this.utility.getDialogConfiguration({});
    const dialogRef2 = this.dialog.open(ConfirmRequestComponent, config);
    dialogRef2.afterClosed().subscribe(page => {
      if (!!page && page.status){
        if(this.user){
          if (this.user.user_type === userType.SELLER || this.user.user_type === userType.ADMIN){
            this.router.navigateByUrl(`/login?type=buyer`);
          } else{
            this.utility.requestOffer(this.product, this.user);
          }
        } else{
          this.router.navigateByUrl(`/login?type=buyer`);
        }
      }
    });
  }

  addToCompare(){
    this.utility.addToCompare(this.product);
  }
}
