<div class="privacy"  (load)="setFocus(privacy)">
    <h1 #privacy>PRIVACY NOTICE</h1>

    <div class="subtitle mar-bottom-2">Last updated May 05, 2022</div>
    <div>This privacy notice for ENSQUAL TECHNOSOLUTIONS PRIVATE LIMITED ('<span class="subtitle">Company</span>', '<span class="subtitle">we</span>', '<span class="subtitle">us</span>', or '<span class="subtitle">our</span>',), describes how and why we might collect, store, use, and/or share ('<span class="subtitle">process</span>') your information when you use our services ('<span class="subtitle">Services</span>'), such as when you:</div>
    <ul>
        <li>Visit our website at <a href="https://www.instrumentflow.com">https://www.instrumentflow.com</a>, or any website of ours that links to this privacy notice</li>
        <li>Engage with us in other related ways, including any sales, marketing, or events</li>
    </ul>
    <div><span class="subtitle">Questions or concerns?</span> Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at enquiry@ensqual.com.</div>
    <br/>
    <div class="heading_1">SUMMARY OF KEY POINTS</div>
    <br/>
    <div class="subtitle"><i>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for. You can also click <span class="link cpointer" (click)="setFocus(toc)">here</span> to go directly to our table of contents.</i></div>
    <br/>
    <div><span class="subtitle">What personal information do we process?</span> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with ENSQUAL TECHNOSOLUTIONS PRIVATE LIMITED and the Services, the choices you make, and the products and features you use. Click <a href="/privacy/#personalinfo">here</a> to learn more.</div>
    <br/>
    <div><span class="subtitle">Do we process any sensitive personal information?</span> We may process sensitive personal information when necessary with your consent or as otherwise permitted by applicable law. Click <a href="/privacy#sensitiveinfo">here</a> to learn more.</div>
    <br/>
    <div><span class="subtitle">Do you receive any information from third parties?</span> We do not receive any information from third parties.</div>
    <br/>
    <div><span class="subtitle">How do you process my information?</span> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Click <a href="/privacy#infouse">here</a> to learn more.</div>
    <br>
    <div><span class="subtitle">In what situations and with which parties do we share personal information?</span> We may share information in specific situations and with specific third parties. Click <a href="/privacy#whoshare">here</a> to learn more.</div>
    <br/>
    <div><span class="subtitle">How do we keep your information safe?</span> We have organisational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Click <a href="/privacy#infosafe">here</a> to learn more.</div>
    <br/>
    <div><span class="subtitle">What are your rights?</span> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Click <a href="/privacy#privacyrights">here</a> to learn more.</div>
    <br/>
    <div><span class="subtitle">How do I exercise my rights?</span> The easiest way to exercise your rights is by filling out our data subject request form available <a data-custom-class="link" href="https://app.termly.io/notify/fa561532-a91f-47e6-a309-f86a78413e4a" rel="noopener noreferrer" target="_blank">here</a>, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</div>
    <br/>
    <div>Want to learn more about what ENSQUAL TECHNOSOLUTIONS PRIVATE LIMITED does with any information we collect? Click <a href="/privacy#toc">here</a> to review the notice in full.</div>
    <br/>
    <br/>
    <div id="toc" #toc class="heading_1">TABLE OF CONTENTS</div>
    <ol>
        <li><a href="/privacy#infocollect">WHAT INFORMATION DO WE COLLECT?</a></li>
        <li><a href="/privacy#infouse">HOW DO WE PROCESS YOUR INFORMATION?</a></li>
        <li><a href="/privacy#legalbases">WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</a></li>
        <li><a href="/privacy#whoshare">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a></li>
        <li><a href="/privacy#inforetain">HOW LONG DO WE KEEP YOUR INFORMATION?</a></li>
        <li><a href="/privacy#infosafe">HOW DO WE KEEP YOUR INFORMATION SAFE?</a></li>
        <li><a href="/privacy#infominors">DO WE COLLECT INFORMATION FROM MINORS?</a></li>
        <li><a href="/privacy#privacyrights">WHAT ARE YOUR PRIVACY RIGHTS?</a></li>
        <li><a href="/privacy#DNT">CONTROLS FOR DO-NOT-TRACK FEATURES</a></li>
        <li><a href="/privacy#caresidents">DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a></li>
        <li><a href="/privacy#policyupdates">DO WE MAKE UPDATES TO THIS NOTICE?</a></li>
        <li><a href="/privacy#contact">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a></li>
        <li><a href="/privacy#request">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a></li>
    </ol>
    <br/>
    <div id="infocollect" class="heading_1">1. WHAT INFORMATION DO WE COLLECT?</div>
    <br/>
    <div id="personalinfo" class="heading_2">Personal information you disclose to us</div>
    <br/>
    <div><span class="subtitle"><i>In Short:</i></span> We collect personal information that you provide to us.</div>
    <br/>
    <div>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</div>
    <br/>
    <div><span class="subtitle">Personal Information Provided by You.</span> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</div>
    <ul>
        <li>names</li>
        <li>phone numbers</li>
        <li>email addresses</li>
        <li>mailing addresses</li>
        <li>job titles</li>
        <li>usernames</li>
        <li>contact or authentication data</li>
        <li>billing addresses</li>
        <li>contact preferences</li>
        <li>passwords</li>
    </ul>
    <div><span class="subtitle">Sensitive Information.</span> When necessary, with your consent or as otherwise permitted by applicable law, we process the following categories of sensitive information:</div>
    <div><span class="subtitle">Payment Data.</span> We may collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument. All payment data is stored by Razorpay . You may find their privacy notice link(s) here: <a href="https://razorpay.com/privacy/">https://razorpay.com/privacy/</a>.</div>
    <br/>
    <div>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</div>
    <br/>
    <div id="personalinfo" class="heading_2">Information automatically collected</div>
    <br/>
    <div><span class="subtitle">In Short:</span> Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.</div>
    <br/>
    <div>We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</div>
    <br/>
    <div>The information we collect includes:</div>
    <ul>
        <li><i>Log and Usage Data.</i> Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps'), and hardware settings).</li>
        <li><i>Device Data.</i> We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.</li>
        <li><i>Location Data.</i> We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.</li>
    </ul>
    <div id="infouse" class="heading_1">2. HOW DO WE PROCESS YOUR INFORMATION?</div>
    <br/>
    <div><i><span class="subtitle">In Short:</span> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</i></div>
    <br/>
    <div class="subtitle">We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</div>
    <ul>
        <li><span class="subtitle">To facilitate account creation and authentication and otherwise manage user accounts.</span> We may process your information so you can create and log in to your account, as well as keep your account in working order.</li>
        <li><span class="subtitle">To save or protect an individual's vital interest.</span> We may process your information when necessary to save or protect an individual's vital interest, such as to prevent harm.</li>
    </ul>
    <div id="legalbases" class="heading_1">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</div>
    <br/>
    <div><i><span class="subtitle">In Short:</span> We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e. legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfil our contractual obligations, to protect your rights, or to fulfil our legitimate business interests.</i></div>
    <br/>
    <div class="subtitle"><i><u>If you are located in the EU or UK, this section applies to you.</u></i></div>
    <br/>
    <div>The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:</div>
    <ul>
        <li><span class="subtitle">Consent.</span> We may process your information if you have given us permission (i.e. consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Click <a href="/privacy#withdrawconsent">here</a> to learn more.</li>
        <li><span class="subtitle">Legal Obligations.</span> We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.</li>
        <li><span class="subtitle">Vital Interests.</span> We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.</li>
    </ul>
    <div class="subtitle"><i><u>If you are located in Canada, this section applies to you.</u></i></div>
    <br/>
    <div>We may process your information if you have given us specific permission (i.e. express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e. implied consent). You can withdraw your consent at any time. Click <a href="/privacy#withdrawconsent">here</a> to learn more.</div>
    <br/>
    <div>In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:</div>
    <ul>
        <li>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</li>
        <li>For investigations and fraud detection and prevention</li>
        <li>For business transactions provided certain conditions are met</li>
        <li>If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim</li>
        <li>For identifying injured, ill, or deceased persons and communicating with next of kin</li>
        <li>If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse</li>
        <li>If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province</li>
        <li>If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records</li>
        <li>If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced</li>
        <li>If the collection is solely for journalistic, artistic, or literary purposes</li>
        <li>If the information is publicly available and is specified by the regulations</li>
    </ul>
    <div id="whoshare" class="heading_1">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</div>
    <br/>
    <div><i><span class="subtitle">In Short:</span> We may share information in specific situations described in this section and/or with the following third parties.</i></div>
    <br/>
    <br/>
    <div>We may need to share your personal information in the following situations:</div>
    <ul>
        <li><span class="subtitle">Business Transfers.</span> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
        <li><span class="subtitle">Affiliates.</span> We may share your information with our affiliates, in which case we will require those affiliates to honour this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.</li>
        <li><span class="subtitle">Business Partners.</span> We may share your information with our business partners to offer you certain products, services, or promotions.</li>
    </ul>
    <div id="inforetain" class="heading_1">5. HOW LONG DO WE KEEP YOUR INFORMATION?</div>
    <br/>
    <div><i><span class="subtitle">In Short:</span> We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice unless otherwise required by law.</i></div>
    <br/>
    <div>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.</div>
    <br/>
    <div>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</div>
    <br/>
    <div id="infosafe" class="heading_1">6. HOW DO WE KEEP YOUR INFORMATION SAFE?</div>
    <br/>
    <div><i><span class="subtitle">In Short:</span> We aim to protect your personal information through a system of organisational and technical security measures.</i></div>
    <br/>
    <div>We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</div>
    <br/>
    <div id="infominors" class="heading_1">7. DO WE COLLECT INFORMATION FROM MINORS?</div>
    <br/>
    <div><i><span class="subtitle">In Short:</span> We do not knowingly collect data from or market to children under 18 years of age.</i></div>
    <br/>
    <div>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at enquiry@ensqual.com.</div>
    <br/>
    <div id="privacyrights" class="heading_1">8. WHAT ARE YOUR PRIVACY RIGHTS?</div>
    <br/>
    <div><i><span class="subtitle">In Short:</span> In some regions, such as the European Economic Area (EEA), United Kingdom (UK), and Canada, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.</i></div>
    <br/>
    <div>In some regions (like the EEA, UK, and Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section <a href="/privacy#contact">'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'</a> below</div>
    <br/>
    <div>We will consider and act upon any request in accordance with applicable data protection laws.</div>
    <br/>
    <div>If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.</div>
    <br/>
    <div>If you are located in Switzerland, the contact details for the data protection authorities are available here: <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">https://www.edoeb.admin.ch/edoeb/en/home.html</a>.</div>
    <br/>
    <div id="withdrawconsent"><span class="subtitle"><u>Withdrawing your consent:</u></span> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section <a href="/privacy#contact">'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'</a> below or updating your preferences.</div>
    <br/>
    <div>However, please note that this will not affect the lawfulness of the processing before its withdrawal, nor when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</div>
    <br/>
    <div><span class="subtitle"><u>Opting out of marketing and promotional communications:</u></span> You can unsubscribe from our marketing and promotional communications at any time by replying 'STOP' or 'UNSUBSCRIBE' to the SMS messages that we send, or by contacting us using the details provided in the section <a href="/privacy#contact">'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'</a> below. You will then be removed from the marketing lists. However, we may still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.</div>
    <br/>
    <div class="heading_2">Account Information</div>
    <br/>
    <div>If you would at any time like to review or change the information in your account or terminate your account, you can:</div>
    <ul>
        <li>Log in to your account settings and update your user account.</li>
    </ul>
    <div>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirement</div>
    <br/>
    <div>If you have questions or comments about your privacy rights, you may email us at enquiry@ensqual.com.</div>
    <br/>
    <div id="DNT" class="heading_1">9. CONTROLS FOR DO-NOT-TRACK FEATURES</div>
    <br/>
    <div>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT') feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognising and implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</div>
    <br/>
    <div id="caresidents" class="heading_1">10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</div>
    <br/>
    <div><i><span class="subtitle">In Short:</span> Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</i></div>
    <br/>
    <div>California Civil Code Section 1798.83, also known as the 'Shine The Light' law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</div>
    <br/>
    <div>If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g. backups, etc.).</div>
    <br/>
    <div id="policyupdates" class="heading_1">11. DO WE MAKE UPDATES TO THIS NOTICE?</div>
    <br/>
    <div><i><span class="subtitle">In Short:</span> Yes, we will update this notice as necessary to stay compliant with relevant laws.</i></div>
    <br/>
    <div>We may update this privacy notice from time to time. The updated version will be indicated by an updated 'Revised' date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</div>
    <br/>
    <div id="contact" class="heading_1">12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</div>
    <br/>
    <div>If you have questions or comments about this notice, you may email us at enquiry@ensqual.com or by post to:</div>
    <br/>
    <div>ENSQUAL TECHNOSOLUTIONS PRIVATE LIMITED</div>
    <div>1005-1006, Titaanium Business Hub</div>
    <div>Althan</div>
    <div>Surat, Gujarat 395007</div>
    <div>India</div>
    <br/>
    <div id="request" class="heading_1">13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</div>
    <br/>
    <div>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please submit a request form by clicking <a href="https://app.termly.io/notify/fa561532-a91f-47e6-a309-f86a78413e4a">here</a>.</div>
    <br/>
    <div>This privacy policy was created using Termly's <a href="https://termly.io/products/privacy-policy-generator">Privacy Policy Generator</a>.</div>
    <br/>
</div>

